var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "app" } }, [
      _c("div", { staticClass: "plane" }, [
        _c("div", { staticClass: "sideA" }, [
          _c("div", { staticClass: "row1" }, [
            _c("div", { staticClass: "a1" }),
            _vm._v(" "),
            _c("div", { staticClass: "a2" }),
            _vm._v(" "),
            _c("div", { staticClass: "a3" }),
            _vm._v(" "),
            _c("div", { staticClass: "b4" })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row2" }, [
            _c("div", { staticClass: "b1" }),
            _vm._v(" "),
            _c("div", { staticClass: "b2" }),
            _vm._v(" "),
            _c("div", { staticClass: "b3" })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "sideB" }, [
          _c("div", { staticClass: "row3" }, [
            _c("div", { staticClass: "d1" }),
            _vm._v(" "),
            _c("div", { staticClass: "d2" }),
            _vm._v(" "),
            _c("div", { staticClass: "d3" })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row4" }, [
            _c("div", { staticClass: "c1" }),
            _vm._v(" "),
            _c("div", { staticClass: "c2" }),
            _vm._v(" "),
            _c("div", { staticClass: "c3" })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d4" })
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-9102f282", { render: render, staticRenderFns: staticRenderFns })
  }
}