var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "modal-mask", on: { click: _vm.close } }, [
      _c(
        "div",
        { staticClass: "modal-wrapper", attrs: { id: "modal-wrapper" } },
        [
          _c("div", { staticClass: "modal-container" }, [
            _c(
              "div",
              { staticClass: "modal-header" },
              [
                _vm._t("header", function() {
                  return [_vm._v("\n            default header\n          ")]
                })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _vm._t("body", function() {
                  return [_vm._v("\n            default body\n          ")]
                })
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [_vm._t("footer")], 2)
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-26040184", { render: render, staticRenderFns: staticRenderFns })
  }
}