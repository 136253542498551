<template>
  <div id="app">

    <div class="plane">
        <div class="sideA">
            <div class="row1">
                <div class="a1"></div>
                <div class="a2"></div>
                <div class="a3"></div>
                <div class="b4"></div>
            </div>

            <div class="row2">
                <div class="b1"></div>
                <div class="b2"></div>
                <div class="b3"></div>
            </div>
        </div>

        <div class="sideB">
            <div class="row3">
                <div class="d1"></div>
                <div class="d2"></div>
                <div class="d3"></div>
            </div>

            <div class="row4">
                <div class="c1"></div>
                <div class="c2"></div>
                <div class="c3"></div>
            </div>
            <div class="d4"></div>
        </div>
    </div>

  </div>
</template>

<script>
export default {
	data: function() {
		return {};
	}
};
</script>

<style scoped>
.plane {
    position: relative;
    width: 300px;
    height: 300px;
    /* background: red; */
    margin: 100px auto;
    transform-style: preserve-3d;
}

.plane div {
    position: absolute;
}

.plane > div {
    height: 50%;
    width: 100%;
}

.plane .sideB {
    top: 50%;
}

.plane > div > div {
    width: 100%;
    height: 50%;
}

.plane > div > div > div, .d4 {
    height: 100%;
    background-color: transparent!important;
    border: 1px solid #0000005c;
    border: 0!important;
    background-image: url(/images/message-placeholder.png);
}

.a2, .a3, .d2, .d3, .c2, .c3, .b2, .b3, .b4, .c4, .d4 {
    border: 0!important;
}

.row2 {
    top: 50%;
}

.row4 {
    top: 50%;
}

.a1 {
    background: yellow;
    width: 50%;
}

.a2 {
    background: cyan;
    width: 25%;
    left: 50%;
    clip-path: polygon(0% 0%, 0% 100%, 100% 100%);
    -webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 100%);
    background-image: url(/images/a2.png)!important;
}

.a3 {
    background: orange;
    width: 25%;
    left: 50%;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
    background-image: url(/images/a3.png)!important;
}

.flying-plane .a3 {
    animation: planeA3 100s linear 1s;
    animation-fill-mode: forwards;
}

@keyframes planeA3 {
  0% { transform: none; }
  1%, 100% { transform: rotate3d(1,1,0,179deg) translateZ(-2px); }
}

.b4 {
    background: brown;
    width: 25%;
    left: 75%;
    transform-origin: left bottom;
    z-index: 100;
    background-position-x: 100%;
}

.flying-plane .b4 {
    animation: planeB4 100s linear 1s;
    animation-fill-mode: forwards;
}

@keyframes planeB4 {
  0% { transform: none; }
  1%, 100% { transform: rotate3d(1,1,0,179deg) translateZ(-2px); }
}


.b1 {
    background: green;
    width: 75%;
    z-index: -1;
    background-position-y: 29%;
}

.b2 {
    background: blue;
    width: 25%;
    left: 75%;
    clip-path: polygon(0% 0%, 0% 100%, 100% 100%);
    -webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 100%);
    background-image: url(/images/b2.png)!important;
}

.b3 {
    background: violet;
    width: 25%;
    left: 75%;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
    background-image: url(/images/b3.png)!important;
}

.flying-plane .b3 {
    animation: planeB3 100s linear 1s;
    animation-fill-mode: forwards;
}

@keyframes planeB3 {
  0% { transform: none; }
  1%, 100% { transform: rotate3d(1,1,0,179deg) translateZ(-2px); }
}


.d1 {
    background: blue;
    width: 75%;
    background-position-y: 60%;
}

.d2 {
    background: red;
    width: 25%;
    left: 75%;
    clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
    background-image: url(/images/d2.png)!important;
}

.d3 {
    background: cyan;
    width: 25%;
    left: 75%;
    clip-path: polygon(0% 100%, 100% 0%, 100% 100%);
    -webkit-clip-path: polygon(0% 100%, 100% 0%, 100% 100%);
    background-image: url(/images/d3.png)!important;
}

.flying-plane .d3 {
    animation: planeD3 100s linear 1s;
    animation-fill-mode: forwards;
}

@keyframes planeD3 {
  0% { transform: none; }
  1%, 100% { transform: rotate3d(-1,1,0,179deg) translateZ(-2px); }
}


.c1 {
    background: rebeccapurple;
    width: 50%;
    z-index: -1;
    background-position-y: 100%;
}

.c2 {
    background: green;
    width: 25%;
    left: 50%;
    clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
    background-image: url(/images/c2.png)!important;
}

.c3 {
    background: darkgreen;
    width: 25%;
    left: 50%;
    clip-path: polygon(0% 100%, 100% 0%, 100% 100%);
    -webkit-clip-path: polygon(0% 100%, 100% 0%, 100% 100%);
    background-image: url(/images/c3.png)!important;
}

.flying-plane .c3 {
    animation: planeC3 100s linear 1s;
    animation-fill-mode: forwards;
}

@keyframes planeC3 {
  0% { transform: none; }
  1%, 100% { transform: rotate3d(-1,1,0,179deg) translateZ(-2px); }
}

.d4 {
    background: orange;
    width: 25%!important;
    left: 75%;
    top: 50%;
    transform-origin: left top;
    z-index: 0;
    background-position-y: 100%;
    background-image: url(/images/message-placeholder.png);
}

.flying-plane .d4 {
    animation: planeD4 100s linear 1s;
    animation-fill-mode: forwards;
}

@keyframes planeD4 {
  0% { transform: none; }
  1% { transform: rotate3d(-1,1,0,179deg) translateZ(-2px); }
  6% { transform: rotate3d(-1,1,0,179deg) translateZ(-2px); z-index: -2; }
  100% { transform: rotate3d(-1,1,0,179deg) translateZ(-2px); z-index: -2; }
}

.sideA {
    transform-style: preserve-3d;
    transform-origin: right bottom;
}

.flying-plane .sideA {
    animation: sideA 100s linear 2s;
    animation-fill-mode: forwards;
}

@keyframes sideA {
  1% { transform: rotateX(-30deg); }
  100% { transform: rotateX(-30deg); }
}

.sideB {
    transform-style: preserve-3d;
	transform-origin: left top;
}

.flying-plane .sideB {
    animation: sideB 100s linear 2s;
    animation-fill-mode: forwards;
}

@keyframes sideB {
    0% { transform: none; }
    1% { transform: rotateX(-170deg); }
    100% { transform: rotateX(-170deg); }
}

.row1 {
    transform-origin: right bottom;
}

.flying-plane .row1 {
    animation: row1 100s linear 3s;
    animation-fill-mode: forwards;
}

@keyframes row1 {
    0% { transform: none; }
    1% { transform: rotateX(-60deg); }
    100% { transform: rotateX(-60deg); }
}

.row4 {
    transform-origin: left top;
}

.flying-plane .row4 {
    animation: row4 100s linear 3s;
    animation-fill-mode: forwards;
}

@keyframes row4 {
    0% { transform: none; }
    1% { transform: rotateX(90deg); }
    100% { transform: rotateX(90deg); }
}

.plane {
    transform-style: preserve-3d;
}

.flying-plane .plane {
    animation: plane 100s linear 3s;
    animation-fill-mode: forwards;
}

@keyframes plane {
    0% { transform: none; }
    1% { transform: rotate3d(1, -1, 0, 30deg) scale(0.5); }
    2% { transform: rotate3d(1, -1, 0, 30deg) translateX(100vw) translateY(-100px) scale(0.5); }
    100% { transform: rotate3d(1, -1, 0, 30deg) translateX(100vw) scale(0.5); }
}

/* plane trans */
/*
transform: rotate3d(0, -1, 1, -12deg);
    transform-style: preserve-3d;


row 4

transform: rotateX(47deg);
transform-origin: 0% 0%;


    */
</style>
