<template>
    <div id="app">
        <footer>
            <span>
                <language-selector></language-selector>
            </span>
            <span>
              <a href="https://www.instagram.com/sharewell.io/">
                <img src="/images/instagram.svg" :alt="$t('footer.instagramAlt')" class="social-icon">
              </a>
            </span>
            <span>
                <a href="/faq">{{ $t('footer.faq') }}</a>
            </span>
            <span>
                <a href="/privacy.html">{{ $t('footer.privacyPolicy') }}</a>
            </span>
            <span>
                <a href="/cgv.html">{{ $t('footer.termsAndConditions') }}</a>
            </span>
            <span>© Sharewell {{(new Date()).getFullYear()}}</span>
        </footer>
    </div>
</template>

<script>
import LanguageSelector from './language_selector.vue';

export default {
    components: {
        LanguageSelector
    },
    data: function() {
        return {};
    }
};
</script>

<style scoped>
footer span {
    margin-left: 20px;
}

a {
    color: #0b6c69;
}

.social-icon {
  width: 50px;
  vertical-align: middle;
}
</style>
