'use strict';

import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from './i18n';


import VueSanitize from "vue-sanitize";
Vue.use(VueSanitize);

import Index from './index.vue';
import CreateCard from './create_card.vue';
import CardCreatedConfirmation from './card_created_confirmation.vue';
import CreateMessage from './create_message.vue';
import MessageCreatedConfirmation from './message_created_confirmation.vue';
import FinishCard from './finish_card.vue';
import RenderedCard from './rendered_card.vue';
import PaperPlaneAnimation from './paper_plane_animation.vue';
import Loader from './loader.vue';
import Metrics from './metrics.vue';
import Faq from './faq.vue';

const routes = [
	{ path: '/', component: Index },
	{ path: '/card', component: CreateCard },
	{ path: '/card_created/:code', component: CardCreatedConfirmation },
	{ path: '/message/:occasion/:recipient/:code', component: CreateMessage },
	{ path: '/message/:code', component: CreateMessage },
	{ path: '/message_created/:code', component: MessageCreatedConfirmation },
	{ path: '/card/:occasion/:recipient/:code/:admin_code', component: FinishCard },
	{ path: '/card/:code/:admin_code', component: FinishCard },
	{ path: '/rendered_card/:code/:admin_code', component: RenderedCard },
	{ path: '/plane', component: PaperPlaneAnimation },
	{ path: '/loader', component: Loader },
	{ path: '/metrics', component: Metrics },
	{ path: '/faq', component: Faq },
];

const router = new VueRouter({
	mode: 'history',
	routes
});

Vue.use(VueRouter);

const app = new Vue({
	router,
	i18n,
}).$mount('#app');
