<template>
  <div id="app">

      <header>
          <h1 class="sharewell">
            <a href="/"><img src="/images/logo/logo.svg" alt="Sharewell"></a>
          </h1>
      </header>

    <div class="envelope-container" v-bind:class="{ 'envelope-closed': isPaperFilled, 'envelope-done': isCardCreated}">
        <form v-on:submit.prevent="fillPaper">
            <div id="envelope" class="envelope">
              <div class="envelope-corner" id="envelope-corner"></div>
              <div class="envelope-top"></div>
              <div class="envelope-back"> </div>
              <div class="paper">
                  <p>
                      <label for="occasion">{{ $t('createCardPage.occasion') }}</label>
                      <input v-model="occasion" :placeholder="$t('createCardPage.occasionPlaceholder')" id="occasion" required>
                  </p>

                  <div>
                      <p>
                          <label for="organizer_email">{{ $t('createCardPage.organizerEmail') }}</label>
                          <input type="email" v-model="organizer_email" :placeholder="$t('createCardPage.emailPlaceholder')" id="organizer_email" required>
                      </p>
                  </div>

                  <p>
                      <label for="deadline">{{ $t('createCardPage.deadline') }}</label>
                      <input type="date" v-model="deadline" id="deadline">
                  </p>

                  <p class="textbox">
                      <label for="message">{{ $t('createCardPage.messageToParticipants') }}</label>
                      <textarea v-model="message" :placeholder="$t('createCardPage.messageToParticipantsPlaceholder')" id="message" required></textarea>
                  </p>

                  <p>
                      <input type="submit" :value="$t('createCardPage.next')">
                  </p>
              </div>
            </div>
        </form>

        <form v-on:submit.prevent="createCard">
            <div id="envelope-front" class="envelope-front">
                <div class="receiver">
                    <p>
                        <label for="receiver_name">{{ $t('createCardPage.recipient') }}</label>
                        <input v-model="receiver_name" :placeholder="$t('createCardPage.recipientPlaceholder')" id="receiver_name" required>
                    </p>

                    <p class="horizontal">
                        <input type="checkbox" id="notifications_on_new_message" v-model="notifications_on_new_message">
                        <label for="notifications_on_new_message">{{ $t('createCardPage.notificationsForNewMessages') }}</label>
                    </p>

                    <p id="create-card">
                        <input type="submit" :value="$t('createCardPage.next')">
                    </p>
                </div>

                <div class="post-stamp-and-lines">
                    <img src="/images/sines.svg" class="sines" alt="post lines">
                    <img src="/images/post-stamp.svg" class="post-stamp" alt="post stamp">
                </div>
                <img src="/images/stamp.svg" class="stamp" alt="stamp">
            </div>
        </form>
    </div>

    <div class="share-card" v-bind:class="{ 'show-share-card': isCardCreated}">
        <div class="fundraising-link">
            <p>
              <img src="/images/moneybag.svg" style="width: 25px; vertical-align: bottom">
              {{ $t('createCardPage.fundraisingQuestion') }}
            </p>
            <form v-on:submit.prevent="updateFundraisingLink">
                <div class="fundraising-url">
                    <input type="text" id="fundraising-link" v-model="fundraising_link" :placeholder="$t('createCardPage.fundraisingLinkPlaceholder')">
                    <input type="submit" :value="$t('createCardPage.save')" class="bubbly-button" v-on:click="animateButton()" :class="{ animate: animate }">
                </div>
            </form>
        </div>

        <h3>{{ $t('createCardPage.inviteParticipantsVia') }}</h3>

        <ul>
            <li v-on:click="isLinkInvitationShown=true" v-bind:class="{ 'selected': isLinkInvitationShown}">{{ $t('createCardPage.link') }}</li>
            <li v-on:click="isLinkInvitationShown=false" v-bind:class="{ 'selected': !isLinkInvitationShown}">{{ $t('createCardPage.email') }}</li>
        </ul>

        <div class="invitations">
            <div class="link-invitation" v-bind:class="{ 'show-link-invitation': isLinkInvitationShown}">
                <p>{{ $t('createCardPage.anyoneCanAddMessage') }}</p>
                <div class="message-url">
                    <span>
                        <img src="/images/chains.svg" alt="link">
                    </span>
                    <input type="text" id="messageURL" v-model="addMessageURL">
                    <button type="button" name="copy" class="copy" data-clipboard-target="#messageURL">{{ $t('createCardPage.copy') }}</button>
                </div>
            </div>

            <div class="email-invitation" v-bind:class="{ 'show-email-invitation': !isLinkInvitationShown}">
                <p>{{ $t('createCardPage.fillEmailList') }}</p>

                <form v-on:submit.prevent="updateCard">
                    <p>
                        <textarea v-model="email_list" :placeholder="$t('createCardPage.participantEmails')"></textarea>
                    </p>

                    <p class="email-submit" v-bind:class="{ 'shown': !emailSent}">
                        <input type="submit" :value="$t('createCardPage.send')">
                    </p>

                    <p class="email-submitted" v-bind:class="{ 'shown': emailSent}">{{ $t('createCardPage.thankYou') }}</p>
                </form>
            </div>
        </div>

    </div>

    <div class="bubbles-bg"></div>

    <sharewell-footer></sharewell-footer>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import ClipboardJS from 'clipboard';
import sharewellFooter from './sharewell_footer.vue';

new ClipboardJS('.copy');

let in_a_week = moment().add(7, 'days');

export default {
	data: function() {
		return {
            occasion: '',
            organizer_email: '',
            receiver_name: '',
            deadline: in_a_week.format('YYYY-MM-DD'),
            message: '',
            email_list: '',
            notifications_on_new_message: true,
            isCardCreated: false,
            isPaperFilled: false,
            addMessageURL: '',
            isLinkInvitationShown: true,
            emailSent: false,
            fundraising_link: '',
            animate: false
		};
	},
    components: {
        sharewellFooter
    },
	created: function() {
		let showConfirmationPage = this.showConfirmationPage;

		setTimeout(() => {
			let envelope = document.getElementById('envelope-corner');

			function whichTransitionEvent() {
				var t;
				var el = document.createElement('fakeelement');
				var transitions = {
					transition: 'transitionend',
					OTransition: 'oTransitionEnd',
					MozTransition: 'transitionend',
					WebkitTransition: 'webkitTransitionEnd'
				};

				for (t in transitions) {
					if (el.style[t] !== undefined) {
						return transitions[t];
					}
				}
			}

			var transitionEvent = whichTransitionEvent();
			transitionEvent &&
				envelope.addEventListener(transitionEvent, function() {
					//showConfirmationPage();
				});
		}, 0);
	},
	methods: {
        animateButton: function() {
            this.animate = true;

            setTimeout(() => {
                this.animate = false;
            }, 700);
        },
		fillPaper: function() {
			this.isPaperFilled = true;
		},
        encodeForURL: function(str, defaultValue = 'page') {
            return str.replace(/[^\w\-~]/g, '') || defaultValue;
        },
		createCard: function() {
			const fields = [
				'occasion',
				'organizer_email',
				'receiver_name',
				'deadline',
				'message',
				/*'email_list',*/
				'notifications_on_new_message'
			];

			let body = {};

			fields.forEach(field => (body[field] = this[field]));

			body.deadline = new Date(body.deadline).getTime();

			axios.post('/card', body).then(response => {
				this.isCardCreated = true;
				this.code = response.data.code;
				this.addMessageURL = `http://${window.location.host}/message/${this.encodeForURL(this.occasion, 'occasion')}/${this.encodeForURL(this.receiver_name, 'receiver')}/${this.code}`;
			});
		},
        updateFundraisingLink: function() {
            axios.put(`/card/${this.code}`, { fundraising_link: this.fundraising_link });
        },
		updateCard: function() {
			axios
				.put(`/card/${this.code}`, {
					email_list: this.email_list
				})
				.then(response => {
					this.emailSent = true;
				});
		},
		showConfirmationPage: function() {
			this.isCardCreated = true;
			this.$router.push({
				path: `/card_created/${this.code}`
			});
		}
	}
};
</script>

<style scoped>
body, html{
height: 100%;
}

body{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: #eee;
position: relative;
overflow: hidden;
background-color: #a5f4f1;
}

header {
    text-align: left;
    padding-left: 50px;
}

.sharewell {
    font-family: Lato;
    color: #f7555f;
    text-shadow: 1px 1px 0px #b4232c, 2px 2px 0px #b4232c, 3px 3px 0px #b4232c;
}

.sharewell img {
  width: 190px;
  max-width: 80%;
}

h3 {
    margin-top: 70px;
    font-size: 40px;
}

.envelope-container {
    position: relative;
    perspective: 800px;
    transform-style: preserve-3d;
    transition: all 0.4s ease-in-out;
    transition-delay: 2.8s;
    margin-top: 190px;
}

.envelope-container.envelope-closed {
    margin-top: -150px;
}

.envelope, .envelope-front{
margin: 0 auto;
margin-top: 200px;
background-color: #e5e5e5;
width: 620px;
height: 400px;
position: relative;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
display: flex;
justify-content: center;
align-items: center;
filter: drop-shadow(0 0 20px  rgba(0,0,0,0.05)) drop-shadow(0 0 10px  rgba(0,0,0,0.05)) drop-shadow(0 0 1px  rgba(0,0,0,0.16));
backface-visibility: hidden;
}

.envelope-front {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0;
    margin: 0 auto;
    right: 0;
    transform: rotateY(180deg);
    background-color: #f4b492;
    pointer-events: none;
}

.envelope-corner{
background-image: url(/images/envelope-cover.svg);
background-repeat: no-repeat;
background-size: 100% 100%;
background-position: top center;
width: 100%;
height: 100%;
position: absolute;
left: 0;
top: -100%;
transform-origin: bottom;
z-index: -1;
}

.envelope-top{
background-image: url(/images/envelope-top.svg);
background-repeat: no-repeat;
background-size: cover;
background-position: center;
width: 100%;
height: 100%;
position: absolute;
left: 0;
bottom: 0;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
z-index: 2;
}

.envelope-back{
background-color: #b58d77;
width: 100%;
height: 100%;
position: absolute;
left: 0;
top: 0;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
}

.paper{
display: flex;
justify-content: space-between;
flex-direction: column;
background-color: #fff;
width: 93%;
height: 93%;
z-index: 10;
margin-bottom: 20px;
box-shadow: 0 0 3px rgba(0,0,0, 0.2);
padding: 12px 20px;
box-sizing: border-box;
position: relative;
margin: 0;
}

.receiver {
    width: 93%;
    margin: 0 auto;
}

.paper p,
.receiver p {
    display: flex;
    flex-direction: column;
    min-height: 50px;
    margin: 0px 0px 19px 0;
}

.paper p label,
.receiver p label {
    font-size: 14px;
    color: #1e605e;
    font-weight: bold;
}

.receiver p label {
    color: #694836;
}

.receiver ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #a7816d;
}
.receiver :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #a7816d;
    opacity: 1;
}
.receiver ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #a7816d;
    opacity: 1;
}
.receiver :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #a7816d;
}
.receiver ::-ms-input-placeholder { /* Microsoft Edge */
    color: #a7816d;
}
.receiver ::placeholder { /* Most modern browsers support this now. */
    color: #a7816d;
}

.receiver input {
    color: #593929;
    border-bottom: 1px dashed #c08667;
}

input, textarea {
    border: 0;
    border-bottom: 1px dashed #d9d9d9;
    font-size: 16px;
    background: transparent;
    padding: 5px 3px;
    resize: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.double-fields {
    display: flex;
    min-height: 50px;
    margin: 0px 20px 6px 0;
}

.paper p.horizontal,
.receiver p.horizontal {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

input[type=submit] {
    background: #ea525c;
    color: white;
    border-style: solid;
    cursor: pointer;
    width: 140px;
    margin: 0 auto;
}

input[type="submit"]:hover {
    background: #ea525c;
}

textarea {
    min-height: 70px;
}

.textbox {
    height: 90px;
    flex-grow: 1;
    flex-shrink: 0;
}

.envelope-closed .envelope-corner {
    transform: rotate3d(1, 0, 0, -180deg);
    transition: 0.4s ease-in all;
    transition-delay: 2.1s;
    z-index: 10000;
}

.envelope-closed .paper {
    animation: letter-in-out 2s;
    animation-fill-mode: forwards;
}

.envelope-closed .envelope-front {
    transform: rotateY(0deg);
    transition: 0.8s transform;
    transition-delay: 2.8s;
    pointer-events: visible;
}

.envelope-closed .envelope {
    transform: rotateY(-180deg);
    transition: 0.8s transform;
    transition-delay: 2.8s;
    pointer-events: none;
    /* backface-visibility: visible; */
}

@keyframes letter-in-out {
    0% {
        transform: translateY(0) scale(1);
    }
    50% {
        transform: translateY(-400px) scale(1.05);
    }
    100% {
        transform: translateY(0) scale(0.95);
        z-index: 0;
    }
}

#create-card {
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
}

.stamp {
    width: 96px;
    height: 116px;
    position: absolute;
    top: 20px;
    right: 20px;
    transform: rotate(4deg);
    z-index: 1000;
}

.post-stamp {
    width: 100px;
    height: 100px;
}

.sines {
    width: 300px;
    height: 100px;
}

.post-stamp-and-lines {
    position: absolute;
    top: 25px;
    right: 40px;
    z-index: 1001;
    opacity: 0;
    transition: all 0.4s ease-in-out;
    transform: scale(1.5);
}

.envelope-done .post-stamp-and-lines {
    transform: scale(1);
    opacity: 1;
}

.envelope-front input[type=submit] {
    opacity: 1;
    transition: 0.4s all ease-in-out;
}

.envelope-done input[type=submit] {
    opacity: 0;
    transform: translateY(100px);
}

.envelope-done {
    transition-delay: 0s;
    transform: translateY(-150px) scale(0.4);
}

.bubbles-bg {
    background-image: url(/images/bubbles.svg);
    height: 200px;
    width: 100%;
    margin: 100px 0 30px 0;
    background-repeat-y: no-repeat;
}

.share-card {
    opacity: 0;
    transform: translateY(0);
    transition: 0.4s all ease-in-out;
    color: #18486f;
}

.share-card.show-share-card {
    opacity: 1;
    transform: translateY(-240px);
    text-align: center;
}

.share-card ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
}

.share-card ul li {
    display: inline-block;
    display: inline-block;
    min-width: 70px;
    padding: 6px;
    margin: 0 10px 20px 10px;
    color: white;
    font-weight: 600;
    cursor: pointer;
    transition: 0.1s all ease-in-out;
}

.share-card ul li.selected,
.share-card ul li:hover {
    border-bottom: 3px solid white;
}

.invitations {
    position: relative;
}

.link-invitation {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.4s all ease-in-out;
    opacity: 0;
    transform: translateX(-200px);
    pointer-events: none;
}

.fundraising-url {
  font-size: 0;
  margin-bottom: 40px;
}

.fundraising-url input {
    background-color: white;
    padding: 10px;
    border: 0;
    min-width: 480px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.fundraising-url input[type=submit] {
  min-width: 130px;
  width: 130px;
  background: #d86164;
  margin: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.message-url {
    font-size: 0;
}

.message-url span {
    height: 39px;
    vertical-align: top;
    border: 0;
    padding: 0 12px 0 12px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: 2px solid #a4f4f14d;
    background-color: white;
    display: inline-block;
    line-height: 56px;
}

.message-url span img {
    width: 20px;
    height: 20px;
}

.message-url input {
    background-color: white;
    padding: 10px;
    border: 0;
    min-width: 480px;
}

.message-url button {
    height: 39px;
    vertical-align: top;
    border: 0;
    font-size: 16px;
    padding: 0 12px 0 12px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left: 2px solid #a4f4f14d;
    cursor: pointer;
    background-color: white;
}

.email-invitation {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.4s all ease-in-out;
    opacity: 0;
    transform: translateX(200px);
    pointer-events: none;
}

.email-invitation textarea {
    background-color: white;
    min-width: 400px;
    min-height: 120px;
    border-radius: 10px;
    padding: 10px;
}

.show-link-invitation,
.show-email-invitation {
    opacity: 1;
    transform: translateX(0);
    pointer-events: auto;
}

.email-submit {
    opacity: 0;
    transform: translateY(100px);
    transition: 0.4s all ease-in-out;
}

.email-submitted {
    opacity: 0;
    transform: translateY(-100px);
    transition: 0.4s all ease-in-out;
}

.email-submit.shown {
    opacity: 1;
    transform: translateY(0);
}

.email-submitted.shown {
    opacity: 1;
    transform: translateY(-35px);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition-delay: 99999s;
}

.bubbly-button {
  padding: 1em 2em;
  margin: 20px auto 40px;
  -webkit-appearance: none;
  appearance: none;
  background-color: #ea525c;
  color: #fff;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  position: relative;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
}
.bubbly-button:focus {
  outline: 0;
}
.bubbly-button:before, .bubbly-button:after {
  position: absolute;
  content: '';
  display: block;
  width: 140%;
  height: 100%;
  left: -20%;
  z-index: -1000;
  transition: all ease-in-out 0.5s;
  background-repeat: no-repeat;
}
.bubbly-button:before {
  display: none;
  top: -75%;
  background-image: radial-gradient(circle, #ea525c 20%, transparent 20%), radial-gradient(circle, transparent 20%, #ea525c 20%, transparent 30%), radial-gradient(circle, #ea525c 20%, transparent 20%), radial-gradient(circle, #ea525c 20%, transparent 20%), radial-gradient(circle, transparent 10%, #ea525c 15%, transparent 20%), radial-gradient(circle, #ea525c 20%, transparent 20%), radial-gradient(circle, #ea525c 20%, transparent 20%), radial-gradient(circle, #ea525c 20%, transparent 20%), radial-gradient(circle, #ea525c 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
}
.bubbly-button:after {
  display: none;
  bottom: -75%;
  background-image: radial-gradient(circle, #ea525c 20%, transparent 20%), radial-gradient(circle, #ea525c 20%, transparent 20%), radial-gradient(circle, transparent 10%, #ea525c 15%, transparent 20%), radial-gradient(circle, #ea525c 20%, transparent 20%), radial-gradient(circle, #ea525c 20%, transparent 20%), radial-gradient(circle, #ea525c 20%, transparent 20%), radial-gradient(circle, #ea525c 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
}
.bubbly-button:active {
  transform: scale(0.9);
  background-color: #c93640;
  box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
}
.bubbly-button.animate:before {
  display: block;
  animation: topBubbles ease-in-out 0.75s forwards;
}
.bubbly-button.animate:after {
  display: block;
  animation: bottomBubbles ease-in-out 0.75s forwards;
}

@keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
  }
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%,  0% 0%,  0% 0%,  0% 0%,  0% 0%;
  }
}
@keyframes bottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
  }
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
    background-size: 0% 0%, 0% 0%,  0% 0%,  0% 0%,  0% 0%,  0% 0%;
  }
}
</style>
