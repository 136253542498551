var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "tooltip" } }, [
    _c(
      "div",
      { staticClass: "tooltip-wrapper", attrs: { id: "tooltip-wrapper" } },
      [
        _c("div", { staticClass: "tooltip-container" }, [
          _c(
            "div",
            { staticClass: "tooltip-body" },
            [
              _vm._t("body", function() {
                return [
                  _vm._v("\n                    default body\n                ")
                ]
              })
            ],
            2
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ce760158", { render: render, staticRenderFns: staticRenderFns })
  }
}