<template>
    <transition name="tooltip">
        <div class="tooltip-wrapper" id="tooltip-wrapper">
            <div class="tooltip-container">
                <div class="tooltip-body">
                    <slot name="body">
                        default body
                    </slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Tooltip',
    data() {
        return {};
    },
    created: async function() {},
    methods: {
        close: function(e) {
            if (e.target.id, e.target.id === 'tooltip-wrapper') this.$emit('close');
        }
    }
};
</script>

<style scoped>
.tooltip-wrapper {
    width: 350px;
    height: 450px;
    max-height: 90%;
    background: white;
    position: absolute;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 1px 1px 10px #0000002e;
}

.tooltip-wrapper:before {
    position: absolute;
    content: '';
    top: -26px;
    right: 18px;
    height: 0;
    width: 0;
    border: 12px solid transparent;
    border-bottom: 15px solid #ffffff;
}
</style>
