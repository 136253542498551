<template>
    <div>
        <header>
            <h1 class="sharewell">
                <a href="/"><img src="/images/logo/logo.svg" :alt="$t('faqPage.logo_alt_text')"></a>
            </h1>
        </header>

        <div class="faq">
            <ol>
                <li><h2>{{ $t('faqPage.faq.terms.title') }}</h2>
                    <ol>
                        <li>
                            <h3>{{ $t('faqPage.faq.terms.items.card.title') }}</h3>
                            <div class="text">{{ $t('faqPage.faq.terms.items.card.text_1') }}</div>
                            <div class="text">{{ $t('faqPage.faq.terms.items.card.text_2') }}</div>
                        </li>

                        <li>
                            <h3>{{ $t('faqPage.faq.terms.items.guest_book.title') }}</h3>
                            <div class="text">{{ $t('faqPage.faq.terms.items.guest_book.text_1') }}</div>
                            <div class="text">{{ $t('faqPage.faq.terms.items.guest_book.text_2') }}</div>
                        </li>

                        <li>
                            <h3>{{ $t('faqPage.faq.terms.items.occasion.title') }}</h3>
                            <div class="text">{{ $t('faqPage.faq.terms.items.occasion.text_1') }}</div>
                            <div class="text">{{ $t('faqPage.faq.terms.items.occasion.text_2') }}</div>
                            <div class="text">{{ $t('faqPage.faq.terms.items.occasion.text_3') }}</div>
                        </li>

                        <li>
                            <h3>{{ $t('faqPage.faq.terms.items.recipient.title') }}</h3>
                            <div class="text">{{ $t('faqPage.faq.terms.items.recipient.text') }}</div>
                        </li>

                        <li>
                            <h3>{{ $t('faqPage.faq.terms.items.participants.title') }}</h3>
                            <div class="text">{{ $t('faqPage.faq.terms.items.participants.text') }}</div>
                        </li>

                        <li>
                            <h3>{{ $t('faqPage.faq.terms.items.qr_code.title') }}</h3>
                            <div class="text">{{ $t('faqPage.faq.terms.items.qr_code.text_1') }}</div>
                            <div class="text">{{ $t('faqPage.faq.terms.items.qr_code.text_2') }}</div>
                        </li>
                    </ol>
                </li>

                <li><h2>{{ $t('faqPage.faq.writing_message.title') }}</h2>
                    <ol>
                        <li><h3>{{ $t('faqPage.faq.writing_message.items.write_message') }}</h3></li>
                        <li><h3>{{ $t('faqPage.faq.writing_message.items.add_photo') }}</h3></li>
                        <li><h3>{{ $t('faqPage.faq.writing_message.items.message_visibility') }}</h3></li>
                    </ol>
                </li>

                <li><h2>{{ $t('faqPage.faq.admin_space.title') }}</h2>
                    <ol>
                        <li><h3>{{ $t('faqPage.faq.admin_space.basics.title') }}</h3>
                            <ol>
                                <li><h4>{{ $t('faqPage.faq.admin_space.basics.admin.title') }}</h4>
                                    <div class="text">{{ $t('faqPage.faq.admin_space.basics.admin.text_1') }}</div>
                                    <div class="text">{{ $t('faqPage.faq.admin_space.basics.admin.text_2') }}</div>
                                </li>

                                <li><h4>{{ $t('faqPage.faq.admin_space.basics.access_card') }}</h4></li>
                            </ol>
                        </li>

                        <li><h3>{{ $t('faqPage.faq.admin_space.style.title') }}</h3>
                            <div class="text">{{ $t('faqPage.faq.admin_space.style.text') }}</div>
                        </li>

                        <li><h3>{{ $t('faqPage.faq.admin_space.settings.title') }}</h3>
                            <ol>
                                <li><h4>{{ $t('faqPage.faq.admin_space.settings.items.title_message_recipient.title') }}</h4>
                                    <div class="text">{{ $t('faqPage.faq.admin_space.settings.items.title_message_recipient.text_1') }}</div>
                                    <div class="text">{{ $t('faqPage.faq.admin_space.settings.items.title_message_recipient.text_2') }}</div>
                                    <div class="text">{{ $t('faqPage.faq.admin_space.settings.items.title_message_recipient.text_3') }}</div>
                                    <div class="text">{{ $t('faqPage.faq.admin_space.settings.items.title_message_recipient.text_4') }}</div>
                                </li>

                                <li><h4>{{ $t('faqPage.faq.admin_space.settings.items.options.title') }}</h4>
                                    <div class="text">{{ $t('faqPage.faq.admin_space.settings.items.options.text') }}</div>
                                </li>
                            </ol>
                        </li>

                        <li><h3>{{ $t('faqPage.faq.admin_space.generate_pdf.title') }}</h3>
                            <div class="text">{{ $t('faqPage.faq.admin_space.generate_pdf.text_1') }}</div>
                            <div class="text">{{ $t('faqPage.faq.admin_space.generate_pdf.text_2') }}</div>
                        </li>
                    </ol>
                </li>

                <li><h2>{{ $t('faqPage.faq.manage_cards.title') }}</h2>
                    <ol>
                        <li><h3>{{ $t('faqPage.faq.manage_cards.share_card.title') }}</h3>
                            <ol>
                                <li><h4>{{ $t('faqPage.faq.manage_cards.share_card.email.title') }}</h4>
                                    <div class="text">{{ $t('faqPage.faq.manage_cards.share_card.email.text') }}</div>
                                </li>

                                <li><h4>{{ $t('faqPage.faq.manage_cards.share_card.url.title') }}</h4>
                                    <div class="text">{{ $t('faqPage.faq.manage_cards.share_card.url.text_1') }}</div>
                                    <div class="text">{{ $t('faqPage.faq.manage_cards.share_card.url.text_2') }}</div>
                                    <div class="text">{{ $t('faqPage.faq.manage_cards.share_card.url.text_3') }}</div>
                                </li>

                                <li><h4>{{ $t('faqPage.faq.manage_cards.share_card.social_network.title') }}</h4>
                                    <div class="text">{{ $t('faqPage.faq.manage_cards.share_card.social_network.text_1') }}</div>
                                    <div class="text">{{ $t('faqPage.faq.manage_cards.share_card.social_network.text_2') }}</div>
                                </li>
                            </ol>
                        </li>

                        <li><h3>{{ $t('faqPage.faq.manage_cards.close_send_card.title') }}</h3>
                            <ol>
                                <li><h4>{{ $t('faqPage.faq.manage_cards.close_send_card.close_message_collection') }}</h4></li>
                                <li><h4>{{ $t('faqPage.faq.manage_cards.close_send_card.send_card.title') }}</h4>
                                    <ol>
                                        <li><h5>{{ $t('faqPage.faq.manage_cards.close_send_card.send_card.email') }}</h5></li>
                                        <li><h5>{{ $t('faqPage.faq.manage_cards.close_send_card.send_card.mail') }}</h5></li>
                                    </ol>
                                </li>
                                <li><h4>{{ $t('faqPage.faq.manage_cards.close_send_card.self_destruction') }}</h4></li>
                            </ol>
                        </li>

                        <li><h3>{{ $t('faqPage.faq.manage_cards.find_cards.title') }}</h3>
                            <ol>
                                <li><h4>{{ $t('faqPage.faq.manage_cards.find_cards.email') }}</h4></li>
                                <li><h4>{{ $t('faqPage.faq.manage_cards.find_cards.account.title') }}</h4>
                                    <ol>
                                        <li><h5>{{ $t('faqPage.faq.manage_cards.find_cards.account.ongoing_cards') }}</h5></li>
                                        <li><h5>{{ $t('faqPage.faq.manage_cards.find_cards.account.archived_cards') }}</h5></li>
                                        <li><h5>{{ $t('faqPage.faq.manage_cards.find_cards.account.received_cards') }}</h5></li>
                                    </ol>
                                </li>
                            </ol>
                        </li>

                        <li><h3>{{ $t('faqPage.faq.manage_cards.print_card.title') }}</h3>
                            <ol>
                                <li><h4>{{ $t('faqPage.faq.manage_cards.print_card.home_print.title') }}</h4>
                                    <ol>
                                        <li><h5>{{ $t('faqPage.faq.manage_cards.print_card.home_print.windows.title') }}</h5>
                                            <div class="text">{{ $t('faqPage.faq.manage_cards.print_card.home_print.windows.text_1') }}</div>
                                            <div class="text">{{ $t('faqPage.faq.manage_cards.print_card.home_print.windows.text_2') }}</div>
                                            <div class="text">{{ $t('faqPage.faq.manage_cards.print_card.home_print.windows.text_3') }}
                                                <ul>
                                                    <li>{{ $t('faqPage.faq.manage_cards.print_card.home_print.windows.landscape') }}</li>
                                                    <li>{{ $t('faqPage.faq.manage_cards.print_card.home_print.windows.double_sided') }}</li>
                                                    <li>{{ $t('faqPage.faq.manage_cards.print_card.home_print.windows.binding') }}</li>
                                                    <li>{{ $t('faqPage.faq.manage_cards.print_card.home_print.windows.margins') }}</li>
                                                </ul>
                                            </div>
                                        </li>

                                        <li><h5>{{ $t('faqPage.faq.manage_cards.print_card.home_print.mac.title') }}</h5>
                                            <div class="text">{{ $t('faqPage.faq.manage_cards.print_card.home_print.mac.text_1') }}</div>

                                            <div class="text">{{ $t('faqPage.faq.manage_cards.print_card.home_print.mac.text_2') }}</div>

                                            <div class="text">{{ $t('faqPage.faq.manage_cards.print_card.home_print.mac.text_3') }}
                                                <ul>
                                                    <li>{{ $t('faqPage.faq.manage_cards.print_card.home_print.mac.landscape') }}</li>
                                                    <li>{{ $t('faqPage.faq.manage_cards.print_card.home_print.mac.double_sided') }}</li>
                                                    <li>{{ $t('faqPage.faq.manage_cards.print_card.home_print.mac.binding') }}</li>
                                                    <li>{{ $t('faqPage.faq.manage_cards.print_card.home_print.mac.margins') }}</li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ol>
                                </li>

                                <li><h4>{{ $t('faqPage.faq.manage_cards.print_card.professional_print.title') }}</h4>
                                    <div class="text">{{ $t('faqPage.faq.manage_cards.print_card.professional_print.text_1') }}</div>

                                    <div class="text">
                                        {{ $t('faqPage.faq.manage_cards.print_card.professional_print.text_2') }}

                                        <ul>
                                            <li>{{ $t('faqPage.faq.manage_cards.print_card.professional_print.landscape') }}</li>
                                            <li>{{ $t('faqPage.faq.manage_cards.print_card.professional_print.double_sided') }}</li>
                                            <li>{{ $t('faqPage.faq.manage_cards.print_card.professional_print.binding') }}</li>
                                            <li>{{ $t('faqPage.faq.manage_cards.print_card.professional_print.margins') }}</li>
                                        </ul>
                                    </div>

                                    <div class="text">{{ $t('faqPage.faq.manage_cards.print_card.professional_print.text_3') }}</div>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
            </ol>

        </div>

        <sharewell-footer></sharewell-footer>
    </div>
</template>

<script>
    import $ from 'jquery';
    import sharewellFooter from './sharewell_footer.vue';

    export default {
        components: {
            sharewellFooter
        },
        mounted() {
            $('h2').click(function() {
                $(this).parent().find('h2 ~ .text').slideToggle();
            });

            $('h3').click(function() {
                $(this).parent().find('h3 ~ .text').slideToggle();
            });

            $('h4').click(function() {
                $(this).parent().find('h4 ~ .text').slideToggle();
            });

            $('h5').click(function() {
                $(this).parent().find('h5 ~ .text').slideToggle();
            });
        }
    }
</script>


<style scoped>
    @import url('https://fonts.googleapis.com/css?family=Quicksand:400,700');

    body {
        background-color: #a5f4f1;
    }

    .sharewell img {
        width: 350px;
        max-width: 80%;
    }

    *, input, textarea {
        font-family: 'Quicksand', sans-serif;
    }

    header {
        text-align: center;
    }

    ol {
        counter-reset: item;
        font-size: 16px!important;
        color: #10514f!important;
        font-weight: bold!important;
    }

    li {
        display: block;
        margin: 18px 0;
    }

    ol > li:before {
        content: counters(item, ".") ". ";
        counter-increment: item;
        opacity: inherit;
    }

    .faq {
        max-width: 800px;
        margin: 50px auto;
    }

    .faq > ol {
        font-size: 20px!important;
        margin-top: 40px;
    }

    .faq > ol > li {
        margin-top: 40px;
    }

    li > ol {
        margin-top: 30px;
    }

    li > ol > li {
        margin-top: 20px;
    }

    .text {
        margin: 8px 0;
        font-weight: normal;
    }

    h2, h3, h4, h5 {
        display: inline;
        font-size: inherit;
        cursor: pointer;
        font-family: 'Quicksand', sans-serif!important;
        color: #10514f;
        font-weight: bold!important;
    }

    h3 {
        opacity: 0.7!important;
    }

    h4 {
        opacity: 0.6!important;
    }

    h5 {
        opacity: 0.5!important;
    }

    .text {
        display: none;
    }

    .text li {
        display: list-item;
    }
</style>