import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './locales/en.json'
import fr from './locales/fr.json'
import es from './locales/es.json'

Vue.use(VueI18n)

const messages = {
    en,
    fr,
    es
}

// Function to get the browser language
function getBrowserLanguage() {
    const language = navigator.language || navigator.userLanguage
    const shortLang = language.split('-')[0]
    return messages[shortLang] ? shortLang : 'en' // Default to 'en' if not supported
}

// Get the stored language or use browser language
const storedLang = localStorage.getItem('lang')
const defaultLang = storedLang || getBrowserLanguage()

const i18n = new VueI18n({
    locale: defaultLang,
    fallbackLocale: 'en',
    messages
})

export default i18n