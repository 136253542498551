<template>
<div class="container">
  <nav>
    <header>
      <h1 class="sharewell">
        <img src="/images/logo/logo.svg" alt="Sharewell">
      </h1>
    </header>

    <ul class="menu">
      <li>
        <a v-on:click="selectedItem='style'; onClickStyle()" v-bind:class="{ 'selected': selectedItem == 'style'}">
          <img src="/images/paint-brush.svg" alt="Style">
          {{ $t('finishCardPage.style') }}
        </a>
      </li>
      <li>
        <a v-on:click="selectedItem='parameters'" v-bind:class="{ 'selected': selectedItem == 'parameters'}">
          <img src="/images/gears.svg" alt="Parameters">
          {{ $t('finishCardPage.parameters') }}
        </a>
      </li>
    </ul>

    <div class="menu-content">

      <div class="styling" v-bind:class="{ 'show-item': selectedItem == 'style'}">

        <p style="text-align: center;">
        <div class="theme-picker" id="theme-picker">
          <div v-for="themePreview in themePreviews" :key="themePreview.name" v-on:click="changeTheme(themePreview.name)" v-bind:class="{'selected': theme == themePreview.name}">
            <picture>
              <source type="image/webp" :srcset="`/images/previews/${themePreview.name}.webp`">
              <source type="image/jp2" :srcset="`/images/previews/${themePreview.name}.jp2`">
              <img :srcset="`/images/previews/${themePreview.name}.png`" :alt="`${themePreview.humanName} theme`">
            </picture>
            <span>{{themePreview.humanName}}</span>
          </div>
        </div>
        </p>

        <button type="button" v-if="!pdf_loading" v-on:click="generateCard()">{{ $t('finishCardPage.generatePDF') }}</button>
        <loader v-if="pdf_loading" class="loader"></loader>
      </div>

      <div class="parameters" v-bind:class="{ 'show-item': selectedItem == 'parameters'}">
        <form v-on:submit.prevent="updateCard">
          <p>
            <label for="occasion">{{ $t('finishCardPage.title') }}</label>
            <textarea v-model="title" :placeholder="$t('finishCardPage.cardTitle')" id="title"></textarea>
          </p>

          <p>
            <label for="recipient">{{ $t('finishCardPage.recipient') }}</label>
            <input v-model="receiver_name" :placeholder="$t('finishCardPage.recipient')" id="recipient" required>
          </p>

          <p>
            <label for="occasion">{{ $t('finishCardPage.occasion') }}</label>
            <input v-model="occasion" :placeholder="$t('finishCardPage.occasion')" id="occasion" required>
          </p>

          <p>
            <label for="fundraising_link">{{ $t('finishCardPage.fundraisingLink') }}</label>
            <input v-model="fundraising_link" :placeholder="$t('finishCardPage.fundraisingLinkPlaceholder')" id="fundraising_link">
          </p>

          <p class="horizontal">
            <input type="checkbox" id="can_upload_images" v-model="can_upload_images">
            <label for="can_upload_images">{{ $t('finishCardPage.allowImageUpload') }}</label>
          </p>

          <p>
            <label for="message">{{ $t('finishCardPage.messageToParticipants') }}</label>
            <textarea class="big-textarea" v-model="message"></textarea>
          </p>

          <p>
            <label for="email_list">{{ $t('finishCardPage.inviteParticipantsByEmail') }}</label>
            <textarea class="big-textarea" v-model="email_list" :placeholder="$t('finishCardPage.participantEmails')"></textarea>
          </p>

          <p class="horizontal">
            <input type="checkbox" id="notifications_on_new_message" v-model="notifications_on_new_message">
            <label for="notifications_on_new_message">{{ $t('finishCardPage.notificationsForNewMessages') }}</label>
          </p>

          <p class="horizontal">
            <input type="checkbox" id="activeCoeurCode" v-model="activeCoeurCode">
            <label for="activeCoeurCode" v-html="$t('finishCardPage.includeCoeurCode')"></label>
          </p>

          <p>
            <button v-if="!saved" class="bubbly-button" v-on:click="animateButton()" :class="{ animate: animate }">{{ $t('finishCardPage.save') }}</button>

            <span class="saved-text" v-if="saved">{{ $t('finishCardPage.saved') }}</span>
          </p>
        </form>
      </div>

    </div>

  </nav>

  <tooltip class="tooltip" v-show="showTooltip">
    <div slot="body">
      <h3>{{ $t('finishCardPage.howToPrintCard') }}</h3>

      <div class="slider">
        <div class="first-step" v-show="printStep == 1">
          <img src="/images/impression-systeme.png">
          <p>{{ $t('finishCardPage.clickFilePrint') }}</p>
        </div>

        <div class="second-step" v-show="printStep == 2">
          <img src="/images/parametres-impression.png" style="width: 350px;">
          <p>{{ $t('finishCardPage.setCorrectPrintSettings') }}</p>
        </div>
      </div>

      <div v-show="printStep == 2" style="text-align: left;">
        <a href="#" @click="printStep=1">{{ $t('finishCardPage.previous') }}</a>
      </div>
      <div v-show="printStep == 1" style="text-align: right;">
        <a href="#" @click="printStep=2">{{ $t('finishCardPage.next') }}</a>
      </div>
    </div>
  </tooltip>

  <div class="info-icon" v-on:click="toggleShowTooltip()">
    <info-icon></info-icon>
  </div>

  <div class="global-generate-btn">
    <button type="button" v-if="!pdf_loading" v-on:click="generateCard()">{{ $t('finishCardPage.generatePDF') }}</button>
    <loader v-if="pdf_loading" class="loader"></loader>
  </div>

  <div class="content" id="content" v-bind:class="card_style">

    <span class="message-url">
      <span>
        <img src="/images/chains.svg" alt="link">
        <span class="share-box-title">{{ $t('finishCardPage.shareLink') }}</span>
      </span>
      <input type="text" id="messageURL" v-model="addMessageURL">
      <button type="button" name="copy" class="copy" data-clipboard-target="#messageURL">{{ $t('finishCardPage.copyLink') }}</button>
    </span>

    <div class="loading-overlay" v-if="loadingTheme">
      <loader class="loader"></loader>
    </div>

    <div class="page">
      <div class="left-page">
        <div id="qrcode-preview" v-show="activeCoeurCode">
          <img :src="coeurCodeUrl">
          <p>{{ $t('finishCardPage.scanCoeurCodeToParticipate') }}</p>
        </div>

        <h5>Made with love by Sharewell</h5>
      </div>
      <div class="right-page">
        <h3 class="card-title">{{title}}</h3>

        <h5>Made with love by Sharewell</h5>
      </div>
    </div>

    <div class="page" v-if="Math.ceil(messages.length / 8) % 2 == 0">
      <div class="left-page">

      </div>

      <div class="right-page">

      </div>
    </div>

    <div class="page" v-for="i in Math.ceil(messages.length / 8)">
      <div class="left-page">
        <ul>
          <li v-for="m in messages.slice((i - 1) * 8, (i - 1) * 8 + 4)" v-bind:class="{ 'img-card': m.image }">
            <div>
              <img :src="m.image" v-if="m.image && can_upload_images"><span v-html="m.text"></span><br>
              <span class="signed-by-container"><span v-html="m.signed_by" class="signed-by"></span></span>
            </div>

            <div class="delete-message" v-on:click="deleteMessage(m._id)">
              &times;
            </div>
          </li>
        </ul>
      </div>

      <div class="right-page">
        <ul>
          <li v-for="m in messages.slice((i - 1) * 8 + 4, (i - 1) * 8 + 8)" v-bind:class="{ 'img-card': m.image }">
            <div>
              <img :src="m.image" v-if="m.image && can_upload_images"><span v-html="m.text"></span><br>
              <span class="signed-by-container"><span v-html="m.signed_by" class="signed-by"></span></span>
            </div>

            <div class="delete-message" v-on:click="deleteMessage(m._id)">
              &times;
            </div>
          </li>
        </ul>
      </div>
    </div>

    <h4 class="deleted-message-separator" v-if="deleted_messages.length > 0">
      {{ $t('finishCardPage.deletedMessagesSeparator') }}
    </h4>

    <div class="page deleted-messages" v-for="i in Math.ceil(deleted_messages.length / 8)">
      <div class="left-page">
        <ul>
          <li v-for="m in deleted_messages.slice((i - 1) * 8, (i - 1) * 8 + 4)" v-bind:class="{ 'img-card': m.image }">
            <div>
              <img :src="m.image" v-if="m.image && can_upload_images"><span v-html="m.text"></span><br>
              <span class="signed-by-container"><span v-html="m.signed_by" class="signed-by"></span></span>
            </div>

            <div class="revert-message" v-on:click="revertMessage(m._id)">
              &#8634;
            </div>
          </li>
        </ul>
      </div>

      <div class="right-page">
        <ul>
          <li v-for="m in deleted_messages.slice((i - 1) * 8 + 4, (i - 1) * 8 + 8)" v-bind:class="{ 'img-card': m.image }">
            <div>
              <img :src="m.image" v-if="m.image && can_upload_images"><span v-html="m.text"></span><br>
              <span class="signed-by-container"><span v-html="m.signed_by" class="signed-by"></span></span>
            </div>

            <div class="revert-message" v-on:click="revertMessage(m._id)">
              &#8634;
            </div>
          </li>
        </ul>
      </div>
    </div>

  </div>

  <div class="content" id="full-size-content" v-bind:class="card_style">
    <div class="page">
      <div class="left-page">
        <div id="qrcode-final" v-show="activeCoeurCode">
          <img :src="coeurCodeUrl">
          <p>{{ $t('finishCardPage.scanCoeurCodeToParticipate') }}</p>
        </div>

        <h5><a href="http://sharewell.io">Made with love by Sharewell</a></h5>
      </div>
      <div class="right-page">
        <h3 class="card-title">{{title}}</h3>
        <h5><a href="http://sharewell.io">Made with love by Sharewell</a></h5>
      </div>
    </div>

    <div class="page" v-if="Math.ceil(messages.length / 8) % 2 == 0">
      <div class="left-page">

      </div>

      <div class="right-page">

      </div>
    </div>

    <div class="page" v-for="i in Math.ceil(messages.length / 8)">
      <div class="left-page">
        <ul>
          <li v-for="m in messages.slice((i - 1) * 8, (i - 1) * 8 + 4)" v-bind:class="{ 'deleted': m.deleted, 'img-card': m.image }">
            <div>
              <img :src="m.image" v-if="m.image && can_upload_images"><span v-html="m.text"></span><br>
              <span class="signed-by-container"><span v-html="m.signed_by" class="signed-by"></span></span>
            </div>

            <div class="delete-message" v-on:click="deleteMessage(m._id)">
              &times;
            </div>
          </li>
        </ul>
      </div>

      <div class="right-page">
        <ul>
          <li v-for="m in messages.slice((i - 1) * 8 + 4, (i - 1) * 8 + 8)" v-bind:class="{ 'deleted': m.deleted, 'img-card': m.image }">
            <div>
              <img :src="m.image" v-if="m.image && can_upload_images"><span v-html="m.text"></span><br>
              <span class="signed-by-container"><span v-html="m.signed_by" class="signed-by"></span></span>
            </div>

            <div class="delete-message" v-on:click="deleteMessage(m._id)">
              &times;
            </div>
          </li>
        </ul>
      </div>
    </div>

  </div>

  <div class="bottom-right-controls">
    <language-selector></language-selector>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import Loader from './loader.vue'
import ClipboardJS from 'clipboard'
import infoIcon from './icons/info.vue'
import tooltip from './tooltip.vue'
import LanguageSelector from './language_selector.vue';

new ClipboardJS('.copy')

export default {
  data: function() {
    return {
      animate: false,
      code: this.$route.params.code,
      admin_code: this.$route.params.admin_code,
      url: `/message/${this.$route.params.code}`,
      receiver_name: '',
      message: '',
      title: '',
      occasion: '',
      email_list: '',
      notifications_on_new_message: false,
      messages: [],
      deleted_messages: [],
      first_clicked_on_styles_at: false,
      selectedItem: 'parameters',
      pdf_loading: false,
      card_style: {
        bubbles: true
      },
      theme: 'bubbles',
      addMessageURL: '',
      saved: false,
      fundraising_link: '',
      can_upload_images: false,
      coeurCodeUrl: '',
      activeCoeurCode: false,
      loadingTheme: true,
      showTooltip: false,
      printStep: 1,
      themePreviews: [{
          name: 'flowery',
          humanName: 'Flowery'
        },
        {
          name: 'keanu',
          humanName: 'Keanu'
        },
        {
          name: 'thanks-for',
          humanName: 'Thanks for…'
        },
        {
          name: 'good-luck',
          humanName: 'Good luck'
        },
        {
          name: 'new-player',
          humanName: 'New player'
        },
        {
          name: 'happy-new-year',
          humanName: 'Happy new year!'
        },
        {
          name: 'dashing-through-year',
          humanName: 'Dashing through the year'
        },
        {
          name: 'someone-special',
          humanName: 'Someone special'
        },
        {
          name: 'little-sprout',
          humanName: "Lil' sprout"
        },
        {
          name: 'stars',
          humanName: 'Reach the stars'
        },
        {
          name: 'lights',
          humanName: 'Lights'
        },
        {
          name: 'balloon',
          humanName: 'Next adventure!'
        },
        {
          name: 'plantyou',
          humanName: 'Plant you'
        },
        {
          name: 'bw-flowers',
          humanName: 'Flowers'
        },
        {
          name: 'donuts',
          humanName: 'Donuts'
        },
        {
          name: 'beers',
          humanName: 'Happy Beerday!'
        },
        {
          name: 'bubbles',
          humanName: 'Bubbles'
        },
        {
          name: 'flowers',
          humanName: 'Flowery painting'
        },
        {
          name: 'happy-sloth',
          humanName: 'Happy Sloth'
        },
        {
          name: 'white-bubbles',
          humanName: 'Bubbles (white)'
        },
        {
          name: 'simple',
          humanName: 'Simple'
        }
      ]
    }
  },
  components: {
    loader: Loader,
    infoIcon,
    tooltip,
    LanguageSelector
  },
  watch: {
    title: function(newTitle, oldTitle) {
      setTimeout(() => {
        let text = this.title.split(' ')
        let last = text.pop()

        $('.card-title').html(function() {
          return twemoji.parse(text.join(' ')) + (text.length > 0 ? ' <span class="last-word">' + twemoji.parse(last) + '</span>' : twemoji.parse(last))
        })
      }, 0)
    }
  },
  created: async function() {
    await this.loadCard()
    window.addEventListener('resize', this.resizeText)
    window.addEventListener('resize', this.resizeImages)
    this.loadTheme()
  },
  methods: {
    encodeForURL: function(str, defaultValue = 'page') {
      return str.replace(/[^\w\-~]/g, '') || defaultValue;
    },
    loadCard: async function() {
      await this.fetchCard()
      this.receiver_name = this.card.receiver_name
      this.message = this.card.message
      this.occasion = this.card.occasion
      this.can_upload_images = this.card.can_upload_images
      this.title = this.card.title || `${this.occasion} ${this.receiver_name}`
      this.email_list = this.card.email_list.join(', ')
      this.notifications_on_new_message = this.card.notifications_on_new_message
      this.addMessageURL = `http://${window.location.host}/message/${this.encodeForURL(this.occasion, 'occasion')}/${this.encodeForURL(this.receiver_name, 'receiver')}/${this.card._id}`
      this.coeurCodeUrl = `http://${window.location.host}/coeur-code?q=${this.addMessageURL}`
      this.fundraising_link = this.card.fundraising_link
      this.first_clicked_on_styles_at = this.card.first_clicked_on_styles_at
    },
    onClickStyle: async function() {
      if (!this.first_clicked_on_styles_at) {
        await axios.put(`/card/${this.$route.params.code}/clicked-on-styles`)
        await this.fetchCard()
        this.first_clicked_on_styles_at = this.card.first_clicked_on_styles_at
      }
    },
    toggleShowTooltip: function() {
      this.showTooltip = !this.showTooltip
    },
    animateButton: function() {
      this.animate = true

      this.updateCard()

      setTimeout(() => {
        this.animate = false
        this.saved = true
        setTimeout(() => {
          this.saved = false
        }, 2000)
      }, 700)
    },
    changeTheme: function(theme) {
      this.loadingTheme = true
      let styleObject = {}
      styleObject[theme] = true
      this.card_style = styleObject
      this.theme = theme
      this.loadCSS(theme)
      this.peristTheme(theme)
    },
    loadCSS: function(theme) {
      let stylesheet = document.createElement('link')
      stylesheet.rel = 'stylesheet'
      stylesheet.href = `/styles/${theme}.css`
      stylesheet.type = 'text/css'
      let links = document.getElementsByTagName('link')
      let godefer = links[links.length - 1]
      godefer.parentNode.insertBefore(stylesheet, godefer.nextSibling)
      stylesheet.onload = () => {
        this.resizeText()
        this.resizeImages()
        this.loadingTheme = false
      }
    },
    peristTheme: function(theme) {
      localStorage.setItem('theme', theme)
    },
    loadTheme: function() {
      let theme = localStorage.getItem('theme')
      if (theme) this.changeTheme(theme)
      else this.changeTheme(this.theme)
    },
    fetchCard: async function() {
      let response = await axios.get(`/card/${this.$route.params.code}`)
      this.card = response.data
      this.messages = this.card.messages.filter(m => !m.deleted)
      this.deleted_messages = this.card.messages.filter(m => m.deleted)
      this.messages = this.splitMessagesWithImage(this.messages)
      this.deleted_messages = this.splitMessagesWithImage(this.deleted_messages)
      setTimeout(() => {
        this.resizeText()
        this.resizeImages()
        this.parseEmojis()
      }, 0)
    },
    parseEmojis: function() {
      this.messages = this.messages.map(message => {
        if (!message.signed_by) return message

        message.signed_by = this.$sanitize(message.signed_by, {
          allowedTags: ['img']
        })

        message.signed_by = twemoji.parse(message.signed_by)

        if (!message.text) return message

        message.text = this.$sanitize(message.text, {
          allowedTags: ['img']
        })

        message.text = twemoji.parse(message.text)

        return message
      })
    },
    splitMessagesWithImage: function(messages) {
      let _messages = []
      messages.forEach(m => {
        let {
          image,
          text,
          ...message
        } = m
        if (m.image) {
          _messages.push({
            image,
            ...message
          })
        }

        if (m.text) {
          _messages.push({
            text,
            ...message
          })
        }
      })
      return _messages
    },
    deleteMessage: async function(_id) {
      let response = await axios.put(`/message/${_id}`, {
        deleted: true,
        admin_code: this.admin_code,
      })

      await this.fetchCard()
    },
    revertMessage: async function(_id) {
      let response = await axios.put(`/message/${_id}`, {
        deleted: false,
        admin_code: this.admin_code,
      })

      await this.fetchCard()
    },
    updateCard: function() {
      const fields = [
        'title',
        'receiver_name',
        'occasion',
        'message',
        'email_list',
        'notifications_on_new_message',
        'fundraising_link',
        'can_upload_images'
      ]

      let body = {}

      fields.forEach(field => (body[field] = this[field]))

      axios.put(`/card/${this.$route.params.code}`, body).then(response => {
        console.log('Saved!')
      })
    },
    resizeText: function() {
      let texts = document.querySelectorAll('.content li div:first-of-type')
      let minSize = 0.1
      let maxSize = 8
      let precision = 0.001

      texts.forEach(text => {
        let low = minSize;
        let high = maxSize;
        let bestFit = minSize;

        while (high - low > precision) {
          const mid = (low + high) / 2
          text.style.fontSize = `${mid}mm`

          // Check if text overflows
          if (text.scrollHeight <= text.clientHeight) {
            // This size fits, so it's a candidate for best fit
            // We continue searching for a larger size that might still fit
            bestFit = mid
            low = mid
          } else {
            // This size is too big, so we need to search lower
            high = mid
          }
        }

        // Set the font size to the largest size that fit
        text.style.fontSize = `${bestFit}mm`
      })
    },
    resizeImages: function() {
      let blocks = document.querySelectorAll('.content li div:first-of-type img')

      blocks.forEach(b => {
        let originalHeight = b.height,
          originalWidth = b.width
        let containerHeight = b.parentElement.offsetHeight * 0.5, // Keep a margin for signature
          containerWidth = b.parentElement.offsetWidth

        if (b.height > containerHeight) {
          let ratio = containerHeight / b.height
          b.width = originalWidth * ratio
          b.height = originalHeight * ratio
        }

        if (b.width > containerWidth) {
          let ratio = containerWidth / b.width
          b.width = originalWidth * ratio
          b.height = originalHeight * ratio
        }
      })
    },
    replaceWithAbsoluteLocation: function(styles) {
      let getUrl = window.location
      let baseUrl = getUrl.protocol + '//' + getUrl.host + '/'

      return styles.replace(/url\(\//g, `url(${baseUrl}`)
    },
    generateCard: async function() {
      let chosenStyle = Object.keys(this.card_style)[0]
      axios.post('/event/card-generated', {
        cardId: this.card._id,
        chosenStyle
      })
      this.pdf_loading = true

      let content = document.getElementById('full-size-content')

      let styles = '';
      [...document.getElementsByTagName('style')].forEach(
        style => (styles += this.replaceWithAbsoluteLocation(style.innerHTML))
      )

      let links = document.querySelectorAll('link[rel="stylesheet"]')

      for (let i = 0; i < links.length; i++) {
        let response = await axios.get(links[i].href)
        styles += this.replaceWithAbsoluteLocation(response.data)
      }

      let page = `<html><head><link href="https://fonts.googleapis.com/css?family=Montserrat:300,500,700|Sacramento|Lato|Archivo+Black|Heebo:100|Nixie+One|Raleway|Parisienne" rel="stylesheet"><style>${styles}</style></head><body>`
      page += `<div class="content pdf-content ${chosenStyle}" id="full-size-content">${
          content.innerHTML
        }</div></body></html>`

      let response = await axios.post(`/to_pdf/${this.$route.params.code}`, {
        html: page
      })

      function converBase64toBlob(content, contentType) {
        contentType = contentType || ''
        var sliceSize = 512
        var byteCharacters = window.atob(content) //method which converts base64 to binary
        var byteArrays = []
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          var slice = byteCharacters.slice(offset, offset + sliceSize)
          var byteNumbers = new Array(slice.length)
          for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
          }
          var byteArray = new Uint8Array(byteNumbers)
          byteArrays.push(byteArray)
        }
        var blob = new Blob(byteArrays, {
          type: contentType
        }) //statement which creates the blob
        return blob
      }

      let blob = converBase64toBlob(response.data, 'application/pdf')

      // let blob = new Blob([response.data], {
      // 	type: 'application/pdf;charset=utf-8;',
      // 	endings: 'native'
      // });
      let a = document.createElement('a')
      a.style = 'display: none'
      document.body.appendChild(a)

      let url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = `${this.card.receiver_name}.pdf`
      a.click()
      window.URL.revokeObjectURL(url)

      this.pdf_loading = false
    }
  }
}
</script>

<style>
img.emoji {
  width: 20px;
  margin: 0 2px;
}
</style>

<style scoped>
*,
input,
textarea {
  font-family: Quicksand;
}

.container {
  display: flex;
}

nav {
  width: 300px;
  max-width: 300px;
  flex-shrink: 0;
  background: hsla(178, 28%, 86%, 1);
  box-shadow: 2px 0 20px #0000002e;
  padding: 0 20px;
  overflow-x: hidden;
  border-right: 1px solid hsla(178, 28%, 81%, 1);
}

.sharewell {
  text-shadow: 0px 0px 12px #b00606;
  margin: 0;
}

.sharewell img {
  width: 350px;
  max-width: 80%;
}

.content {
  height: 100vh;
  overflow: auto;
  background: #f9f9f9;
  width: 100%;
  padding-bottom: 300px;
}

header {
  text-align: center;
}

h1 {
  font-family: 'Montserrat';
  color: white;
  font-weight: 400;
  letter-spacing: 5px;
  text-transform: uppercase;
  text-align: center;
}

.sharewell {
  font-family: Lato;
  color: #f7555f;
  text-shadow: 1px 1px 0px #b4232c, 2px 2px 0px #b4232c, 3px 3px 0px #b4232c;
}

body {
  background-color: #a5f4f1;
  /*rgba(243, 139, 86, 0.64);*/
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #eee;
  position: relative;
  font-family: Montserrat;
  overflow: hidden;
}

.card-message {
  color: #18486f;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.card-message:before {
  content: '\201C';
  font-size: 40px;
  font-family: serif;
  position: relative;
  left: -10px;
}

.card-message:after {
  content: '\201D';
  font-size: 40px;
  font-family: serif;
  position: relative;
  top: 30px;
  left: 10px;
}

.content ul {
  padding: 10px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  width: 100%;
  margin: 0;
}

.content li {
  width: auto;
  max-height: 100%;
  display: block;
  background: white;
  margin: 10px;
  padding: 10px;
  border-radius: 15px;
  position: relative;
}

.content li div {
  overflow: hidden;
  word-break: break-word;
  white-space: pre-wrap;
  max-height: 100%;
}

.content li span.signed-by-container {
  text-align: right;
  display: block;
}

p label {
  font-size: 14px;
  color: hsla(178, 67%, 27%, 1);
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #5a7271;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #5a7271;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #5a7271;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #5a7271;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #5a7271;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #5a7271;
}

input,
textarea {
  border: none;
  font-size: 16px;
  background: #fffffff5;
  padding: 9px 12px;
  width: 278px;
  margin: 10px 0;
  border-radius: 10px;
  font-family: Quicksand;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

input[type='submit'],
button {
  background: #ea525c;
  color: white;
  border-style: solid;
  cursor: pointer;
  width: auto;
  margin: 0 auto;
  font-size: 16px;
  border: 1px solid #ff4949;
  display: block;
  padding: 8px 30px;
}

input[type='submit']:hover {
  background: #ea525c;
}

.delete-message {
  width: 30px;
  height: 30px;
  background-color: #ff6767;
  color: #ffffff !important;
  line-height: 30px;
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: 50%;
  font-size: 34px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  cursor: pointer;
  font-family: Montserrat;
  white-space: normal !important;
}

li:hover .delete-message {
  opacity: 1;
}

.revert-message {
  width: 30px;
  height: 30px;
  background-color: #92ff67;
  color: #ffffff !important;
  line-height: 30px;
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: 50%;
  font-size: 21px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  cursor: pointer;
  font-family: Montserrat;
  white-space: normal !important;
}

li:hover .revert-message {
  opacity: 1;
}

.content .deleted {
  display: none;
}

p.horizontal {
  display: flex;
  flex-direction: row;
}

p.horizontal input {
  width: auto;
  margin: 0 10px;
}

p.horizontal label {
  flex-shrink: 1;
}

ul.menu {
  list-style-type: none;
  padding: 0;
  display: flex;
  margin: 10px -20px 0 -20px;
  border-left: 0;
  border-right: 0;
  margin-top: 10px;
}

ul.menu li {
  flex-grow: 1;
  text-align: center;
  font-family: Quicksand;
  text-transform: uppercase;
  font-size: 14px;
  width: 50%;
}

ul.menu li a {
  cursor: pointer;
  display: block;
  padding: 10px;
  color: hsla(178, 67%, 20%, 1);
  opacity: 0.7;
  border-bottom: 3px solid #325453;
}

ul.menu li a:hover {
  opacity: 0.8;
}

ul.menu li a.selected {
  opacity: 1;
}

ul.menu img {
  width: 30px;
  display: inline-block;
  margin: 10px auto 0px;
  margin-top: 0;
  vertical-align: middle;
  margin-right: 6px;
}

.parameters {
  transition: 0.4s all ease-in-out;
  opacity: 0;
  transform: translateX(300px);
  pointer-events: none;
}

.styling {
  transition: 0.4s all ease-in-out;
  opacity: 0;
  transform: translateX(-300px);
  pointer-events: none;
}

.show-item {
  opacity: 1;
  transform: translateX(0);
  pointer-events: auto;
}

#full-size-content .page {
  width: 29.7cm;
  height: 21cm;
  margin: 0;
  box-shadow: none;
}

#full-size-content .page li {}

.pdf-content .page li div {
  max-height: none !important;
}

.pdf-content {
  left: 0;
  position: static;
}

#full-size-content {
  position: absolute;
  left: -100000px;
}

#full-size-content a {
  color: inherit;
  text-decoration: inherit;
  font-weight: inherit;
}

@page {
  size: 29.7cm 21cm;
}

.loader {
  text-align: center;
}

.menu-content {
  position: relative;
}

.menu-content>div {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.theme-picker {
  height: 430px;
  background: #ffffff8a;
  border: 1px solid hsla(178, 27%, 77%, 1);
  overflow: auto;
  margin: 0 -20px;
  border-right: 0;
  border-left: 0;
}

.theme-picker>div {
  width: 100%;
  height: 120px;
  background: white;
  margin: 0px auto;
  cursor: pointer;
  overflow: hidden;
  filter: grayscale(70%);
  transition: 0.2s all ease-in-out;
}

.theme-picker>div img {
  width: 100%;
  margin-top: -53px;
}

.message-url {
  font-size: 0;
  display: flex;
  width: 70%;
  margin: 20px auto;
  box-shadow: 2px 2px 5px #0000002e;
  border-radius: 10px;
}

.message-url span {
  height: 40px;
  vertical-align: top;
  border: 0;
  padding: 0 6px 0 12px;
  border-right: 1px solid #d5e4e4;
  background-color: white;
  line-height: 46px;
  color: #325453;
  align-items: center;
  display: flex;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.message-url span img {
  width: 20px;
  height: 20px;
}

.message-url input {
  background-color: white;
  padding: 10px;
  border: 0;
  min-width: 160px;
  margin: 0;
  border-radius: 0;
  flex-grow: 1;
  text-align: center;
}

.message-url button {
  height: 40px;
  vertical-align: top;
  border: 0;
  font-size: 16px;
  padding: 0 12px 0 12px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
}

.theme-picker .selected {
  opacity: 1;
  filter: grayscale(0%);
}

.theme-picker .selected span {
  background: #d86164 !important;
  color: white !important;
  text-shadow: 1px 1px 0px #00000059;
}

.theme-picker div {
  position: relative;
}

.theme-picker div span {
  position: absolute;
  bottom: 5px;
  left: 5px;
  text-align: right;
  font-family: Quicksand;
  text-shadow: 1px 1px 0px white;
  font-weight: bold;
  color: #4b4b4b;
  background: #ffffffbf;
  display: inline-block;
  width: auto;
  padding: 2px 7px;
  box-shadow: -1px 1px 1px #00000052;
  border-radius: 5px;
  font-size: 12px;
}

.deleted-message-separator {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #00000070;
  text-shadow: 1px 1px 3px #fff, 0 0 0 #000, 1px 1px 3px #fff;
  padding-top: 20px;
  margin-top: 50px;
  border-top: 1px dashed #84848478;
}

.deleted-messages {
  opacity: 0.5;
}

.right-page h3 {
  white-space: pre-wrap;
}

.left-page,
.right-page {
  position: relative;
}

@font-face {
  font-family: 'Glacial Indifference';
  font-weight: 100;
  font-style: normal;
  src: url('https://s3.eu-west-3.amazonaws.com/sharewell/fonts/GlacialIndifference.woff') format('woff');
}

h5 {
  margin: 2% auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-family: 'Glacial Indifference';
  text-transform: uppercase;
  font-size: 0.8vw;
}

.bubbly-button {
  font-family: 'Helvetica', 'Arial', sans-serif;
  display: block;
  font-size: 1em;
  padding: 1em 2em;
  margin: 20px auto 40px;
  -webkit-appearance: none;
  appearance: none;
  background-color: #ea525c;
  color: #fff;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  position: relative;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
}

.bubbly-button:focus {
  outline: 0;
}

.bubbly-button:before,
.bubbly-button:after {
  position: absolute;
  content: '';
  display: block;
  width: 140%;
  height: 100%;
  left: -20%;
  z-index: -1000;
  transition: all ease-in-out 0.5s;
  background-repeat: no-repeat;
}

.bubbly-button:before {
  display: none;
  top: -75%;
  background-image: radial-gradient(circle, #ea525c 20%, transparent 20%),
    radial-gradient(circle, transparent 20%, #ea525c 20%, transparent 30%),
    radial-gradient(circle, #ea525c 20%, transparent 20%), radial-gradient(circle, #ea525c 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, #ea525c 15%, transparent 20%),
    radial-gradient(circle, #ea525c 20%, transparent 20%), radial-gradient(circle, #ea525c 20%, transparent 20%),
    radial-gradient(circle, #ea525c 20%, transparent 20%), radial-gradient(circle, #ea525c 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
}

.bubbly-button:after {
  display: none;
  bottom: -75%;
  background-image: radial-gradient(circle, #ea525c 20%, transparent 20%),
    radial-gradient(circle, #ea525c 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, #ea525c 15%, transparent 20%),
    radial-gradient(circle, #ea525c 20%, transparent 20%), radial-gradient(circle, #ea525c 20%, transparent 20%),
    radial-gradient(circle, #ea525c 20%, transparent 20%), radial-gradient(circle, #ea525c 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
}

.bubbly-button:active {
  transform: scale(0.9);
  background-color: #c93640;
  box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
}

.bubbly-button.animate:before {
  display: block;
  animation: topBubbles ease-in-out 0.75s forwards;
}

.bubbly-button.animate:after {
  display: block;
  animation: bottomBubbles ease-in-out 0.75s forwards;
}

@keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }

  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
  }

  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

@keyframes bottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
  }

  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
  }

  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

.saved-text {
  color: green;
  font-size: 1em;
  padding: 1em 2em;
  margin: 20px auto 40px;
  text-align: center;
  display: block;
}

li img {
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
}

form {
  margin-top: 20px;
}

.big-textarea {
  min-height: 80px;
}

#qrcode-preview {
  margin: 0 auto;
  opacity: 0.8;
  position: relative;
  width: 13vw;
  text-align: center;
  margin-top: 8vw;
}

#qrcode-final {
  margin: 0 auto;
  opacity: 0.8;
  position: relative;
  width: 13vw;
  text-align: center;
  margin-top: 15vw;
}

#qrcode-preview img {
  width: 8vw !important;
  height: 8vw !important;
}

#qrcode-final img {
  width: 15vw !important;
  height: 15vw !important;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 341px;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background: #f9f9f9;
  z-index: 1000;
  justify-content: center;
}

.img-card {
  padding: 0 !important;
}

.img-card div {
  border-radius: 15px !important;
}

.img-card span.signed-by-container {
  position: absolute;
  bottom: 10px;
  right: 15px;
}

.share-box-title {
  font-size: 16px;
  padding: 0 6px 0 16px;
  min-width: 120px;
  border: 0 !important;
  line-height: 15px;
}

.signed-by {
  display: inline-block !important;
}

.signed-by:before {
  content: '— ';
}

.info-icon {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  color: #d86164;
  width: 30px;
  cursor: pointer;
}

.info-icon:hover {
  color: #a7373a;
}

.tooltip {
  position: absolute;
  top: 70px;
  right: 15px;
  z-index: 100;
}

.tooltip h3 {
  font-size: 20px;
  margin-top: 0;
  font-weight: 700;
}

.tooltip img {
  margin: 0 auto;
  display: block;
  box-shadow: 2px 2px 5px #0000002e;
}

.tooltip p {
  text-align: center;
}

.tooltip a {
  background: #276160;
  font-weight: 800;
  text-align: center;
  display: inline-block;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
}

.tooltip a:hover {
  background: #174b4a;
}

.global-generate-btn {
  position: fixed;
  bottom: 0;
  padding: 20px 0;
  left: 341px;
  right: 0px;
  z-index: 1000;
  background: #ffffff70;
  box-shadow: 0px -18px 26px #ffffff70;
}

.bottom-right-controls {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}
</style>
