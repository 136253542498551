<template>
  <transition name="modal">
    <div class="modal-mask" @click="close">
      <div class="modal-wrapper" id="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <slot name="header">
              default header
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              default body
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <!--<button class="modal-default-button" @click="$emit('close')">
                Close
            </button>-->
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
    name: 'Modal',
    data() {
        return {};
    },
    created: async function() {},
    methods: {
        show: function(form) {
            this.currentForm = form;
            this.registerForm.error = '';
        },
        close: function(e) {
            if (e.target.id, e.target.id === 'modal-wrapper') this.$emit('close');
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 60%;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
  max-height: 80%;
  overflow: auto;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 30px 0 30px;
  padding: 20px 30px;
}

.modal-header {
  padding: 1px;
  background: #f1f1f1;
}

.modal-footer {
  padding: 0 30px 20px 30px;
}

.modal-default-button {
  display: block;
  margin: 20px auto 0 auto;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* Phone media queries */
@media (max-width: 767px) {
    .modal-container {
        width: 100%;
        max-height: 90%;
        border-radius: 0;
    }
}
</style>
