var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ins", {
    staticClass: "adsbygoogle",
    staticStyle: { display: "block" },
    attrs: {
      "data-ad-client": "ca-pub-1610284649584943",
      "data-ad-slot": "1109798194",
      "data-ad-format": "auto",
      "data-full-width-responsive": "true"
    }
  })
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4b101d2f", { render: render, staticRenderFns: staticRenderFns })
  }
}