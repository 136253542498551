<template>
  <div>
      <div class="first-page">
          <div class="left-page">
          </div>

          <div class="right-page">
              <h3>For {{recipient}}<br>{{occasion}}</h3>
          </div>
      </div>

      <div class="second-page">

          <div class="row" v-for="i in Math.ceil(messages.length / 4)">
              <ul>
                  <li v-for="m in messages.slice((i - 1) * 4, i * 4)">
                      <div :class="sizeClass(m.text)">
                          {{m.text}}
                          <br>
                          <span>– {{m.signed_by}}</span>
                      </div>
                  </li>
              </ul>
          </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
	data: function() {
		return {
			code: this.$route.params.code,
			admin_code: this.$route.params.admin_code,
			url: `/message/${this.$route.params.code}`,
			recipient: '',
			message: '',
			occasion: '',
			messages: []
		};
	},
	created: async function() {
		await this.fetchCard();
		this.recipient = this.card.receiver_name;
		this.message = this.card.message;
		this.occasion = this.card.title;
	},
	methods: {
		fetchCard: async function() {
			let response = await axios.get(`/card/${this.$route.params.code}`);
			this.card = response.data;
			this.messages = this.card.messages.filter(m => !m.deleted);
		},
		sizeClass: function(text) {
			if (text.length < 50) return 'text-xl';
			else if (text.length < 130) return 'text-lg';
			else if (text.length < 250) return 'text-md';
			else if (text.length < 500) return 'text-sm';
			else if (text.length < 750) return 'text-xs';
			else return 'text-xxs';
		}
	}
};
</script>

<style scoped>
*, input, textarea {
    font-family: Montserrat;
}

header {
    text-align: center;
}

h1 {
    font-family: "Montserrat";
    color: white;
    font-weight: 400;
    letter-spacing: 5px;
    text-transform: uppercase;
    text-align: center;
}

.sharewell {
    font-family: Lato;
    color: #f7555f;
}

h2 {
    font-family: Sacramento;
    color: white;
    font-size: 86px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 0;
}

h3 {
    font-family: Sacramento;
    color: #f7555f;
    font-size: 46px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 200px;
    padding: 0 50px;
}

body {
    background-color: #a5f4f1; /*rgba(243, 139, 86, 0.64);*/
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #eee;
    position: relative;
    font-family: Montserrat;
    overflow: hidden;
}

.card-message {
    color: #18486f;
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
}

.card-message:before {
    content: '\201C';
    font-size: 40px;
    font-family: serif;
    position: relative;
    left: -10px;
}

.card-message:after {
    content: '\201D';
    font-size: 40px;
    font-family: serif;
    position: relative;
    top: 30px;
    left: 10px;
}

ul {
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
}

li {
    flex-grow: 1;
    width: 25%;
    max-width: 25%;
    list-style-type: none;
}

li > div {
    display: block;
    background: white;
    margin: 10px;
    padding: 20px;
    border-radius: 15px;
}

li span {
    text-align: right;
    display: block;
    margin-top: 15px;
}

.first-page {
    display: flex;
}

.left-page, .right-page {
    width: 50%;
}

.second-page {
    page-break-after: always;
}

.page-break {
    page-break-before: always;
}

.line-break {

}

.text-xxs {
    font-size: 6pt;
}

.text-xs {
    font-size: 8pt;
}

.text-sm {
    font-size: 10pt;
}

.text-md {
    font-size: 12pt;
}

.text-lg {
    font-size: 14pt;
}

.text-xl {
    font-size: 16pt;
}

.row {
    display: block;
    background: transparent;
    page-break-inside: avoid;
    padding: 0;
    padding-top: 10px;
    background-image: url(/images/bubbles.svg);
    background-repeat-y: no-repeat;
    background-position-y: 100%;
}

</style>
