<template>
<div id="app">

  <h1>Metrics</h1>

  <div class="graphs">
    <div class="card">
      <canvas id="card-chart" width="400" height="300"></canvas>
    </div>

    <div class="card">
      <canvas id="message-chart" width="400" height="300"></canvas>
    </div>

    <div class="card">
      <canvas id="generation-chart" width="400" height="300"></canvas>
    </div>

    <div class="card">
      <canvas id="generation-style-chart" width="400" height="300"></canvas>
    </div>

    <div class="card">
      <ol>
        <li v-for="(theme, index) in generationStylesData.labels" :key="theme">
          <picture>
            <source type="image/webp" :srcset="`/images/previews/${theme}.webp`">
            <source type="image/jp2" :srcset="`/images/previews/${theme}.jp2`">
            <img :srcset="`/images/previews/${theme}.png`" :alt="`${theme} theme`">
          </picture>
          <span>{{theme}} ({{generationStylesData.data[index]}})</span>
        </li>
      </ol>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios';
import Chart from 'chart.js/auto';

const groupByMonth = (timestamps) => {
  const groupedByMonth = {}; // {'YEAR-MONTH': count}

  timestamps.forEach(t => {
    const yearMonth = getYearMonth(t);
    groupedByMonth[yearMonth] = groupedByMonth[yearMonth] || 0;
    groupedByMonth[yearMonth]++;
  });

  return groupedByMonth;
};

const getYearMonth = (timestamp) => {
  const date = new Date(timestamp);
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
};

const hashToLabelsData = (groupedByHash) => {
  const labelsData = Object.keys(groupedByHash).map(k => [k, groupedByHash[k]]).sort((x, y) => x[0].localeCompare(y[0]));
  return {
    labels: labelsData.map(x => x[0]),
    data: labelsData.map(x => x[1])
  };
};

const hashToLabelsDataSortedByData = (groupedByHash) => {
  const labelsData = Object.keys(groupedByHash).map(k => [k, groupedByHash[k]]).sort((x, y) => y[1] - x[1]);
  return {
    labels: labelsData.map(x => x[0]),
    data: labelsData.map(x => x[1])
  };
};

const countGenerationStyles = (generations) => {
  const stylesCount = {};

  generations.forEach(g => {
    stylesCount[g.style] = stylesCount[g.style] || 0;
    stylesCount[g.style]++;
  });

  return stylesCount;
};

const randomColor = (() => {
  "use strict";

  const randomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  return () => {
    var h = randomInt(0, 360);
    var s = randomInt(42, 98);
    var l = randomInt(40, 90);
    return `hsl(${h},${s}%,${l}%)`;
  };
})();

export default {
  data: function() {
    return {
      datacollection: null,
      numCards: 0,
      numMessages: 0,
      cardTimestamps: [],
      messageTimestamps: [],
      generationTimestamps: [],
      generations: [],
      generationStylesData: [],
    };
  },
  created: async function() {
    await this.getStats();
    const cardsData = hashToLabelsData(groupByMonth(this.cardTimestamps));
    const messagesData = hashToLabelsData(groupByMonth(this.messageTimestamps));
    const generationsData = hashToLabelsData(groupByMonth(this.generationTimestamps));
    const generationStylesData = hashToLabelsDataSortedByData(countGenerationStyles(this.generations));
    this.generationStylesData = generationStylesData;

    const configCards = {
      labels: cardsData.labels,
      datasets: [{
        label: 'Cards created',
        data: cardsData.data,
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }]
    };
    this.setupChart(configCards, document.getElementById('card-chart'));

    const configMessages = {
      labels: messagesData.labels,
      datasets: [{
        label: 'Messages written',
        data: messagesData.data,
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }]
    };
    this.setupChart(configMessages, document.getElementById('message-chart'));

    const configGenerations = {
      labels: generationsData.labels,
      datasets: [{
        label: 'Cards PDF generated',
        data: generationsData.data,
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }]
    };
    this.setupChart(configGenerations, document.getElementById('generation-chart'));

    const configGenerationStyles = {
      labels: generationStylesData.labels,
      datasets: [{
        label: 'Popular styles',
        data: generationStylesData.data,
        backgroundColor: generationStylesData.data.map(_ => randomColor()),
        hoverOffset: 4,
      }]
    };
    this.setupChart(configGenerationStyles, document.getElementById('generation-style-chart'), 'doughnut');
  },
  methods: {
    getStats: async function() {
      let response = await axios.get('/stats');
      this.numCards = response.data.numCards;
      this.numMessages = response.data.numMessages;
      this.cardTimestamps = response.data.cardTimestamps;
      this.messageTimestamps = response.data.messageTimestamps;
      this.generationTimestamps = response.data.cardGenerations.map(g => g.created_at);
      this.generations = response.data.cardGenerations;
    },
    setupChart(data, target, type = 'line') {
      const config = {
        type,
        data
      };
      if (type == 'line') {
        config.options = {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        };
      }
      new Chart(target, config);
    }
  }
};
</script>

<style scoped>
h1 {
  color: #f35151;
  margin-left: 30px;
  border-bottom: 3px solid #ed7373;
  display: inline-block;
  padding-bottom: 5px;
  padding: 0 10px 5px 0;
}

.graphs {
  display: grid;
  grid-template-columns: 50% 50%;
}

.card {
  max-width: 600px;
  width: 100%;
  margin: 20px auto;
  display: inline-block;
  vertical-align: top;
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .graphs {
    grid-template-columns: 100%;
  }
}

ol li {
  margin-bottom: 20px;
  padding-left: 10px;
  font-size: 1.3em;
}

ol li img {
  width: 100px;
  margin-right: 10px;
  vertical-align: middle;
}
</style>
