var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("header", [
        _c("h1", { staticClass: "sharewell" }, [
          _c("a", { attrs: { href: "/" } }, [
            _c("img", {
              attrs: {
                src: "/images/logo/logo.svg",
                alt: _vm.$t("faqPage.logo_alt_text")
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "faq" }, [
        _c("ol", [
          _c("li", [
            _c("h2", [_vm._v(_vm._s(_vm.$t("faqPage.faq.terms.title")))]),
            _vm._v(" "),
            _c("ol", [
              _c("li", [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t("faqPage.faq.terms.items.card.title")))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.$t("faqPage.faq.terms.items.card.text_1")))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.$t("faqPage.faq.terms.items.card.text_2")))
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$t("faqPage.faq.terms.items.guest_book.title"))
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text" }, [
                  _vm._v(
                    _vm._s(_vm.$t("faqPage.faq.terms.items.guest_book.text_1"))
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text" }, [
                  _vm._v(
                    _vm._s(_vm.$t("faqPage.faq.terms.items.guest_book.text_2"))
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$t("faqPage.faq.terms.items.occasion.title"))
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text" }, [
                  _vm._v(
                    _vm._s(_vm.$t("faqPage.faq.terms.items.occasion.text_1"))
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text" }, [
                  _vm._v(
                    _vm._s(_vm.$t("faqPage.faq.terms.items.occasion.text_2"))
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text" }, [
                  _vm._v(
                    _vm._s(_vm.$t("faqPage.faq.terms.items.occasion.text_3"))
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$t("faqPage.faq.terms.items.recipient.title"))
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text" }, [
                  _vm._v(
                    _vm._s(_vm.$t("faqPage.faq.terms.items.recipient.text"))
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$t("faqPage.faq.terms.items.participants.title"))
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text" }, [
                  _vm._v(
                    _vm._s(_vm.$t("faqPage.faq.terms.items.participants.text"))
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$t("faqPage.faq.terms.items.qr_code.title"))
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text" }, [
                  _vm._v(
                    _vm._s(_vm.$t("faqPage.faq.terms.items.qr_code.text_1"))
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text" }, [
                  _vm._v(
                    _vm._s(_vm.$t("faqPage.faq.terms.items.qr_code.text_2"))
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("h2", [
              _vm._v(_vm._s(_vm.$t("faqPage.faq.writing_message.title")))
            ]),
            _vm._v(" "),
            _c("ol", [
              _c("li", [
                _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("faqPage.faq.writing_message.items.write_message")
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("faqPage.faq.writing_message.items.add_photo")
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "faqPage.faq.writing_message.items.message_visibility"
                      )
                    )
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("h2", [_vm._v(_vm._s(_vm.$t("faqPage.faq.admin_space.title")))]),
            _vm._v(" "),
            _c("ol", [
              _c("li", [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t("faqPage.faq.admin_space.basics.title")))
                ]),
                _vm._v(" "),
                _c("ol", [
                  _c("li", [
                    _c("h4", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("faqPage.faq.admin_space.basics.admin.title")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("faqPage.faq.admin_space.basics.admin.text_1")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("faqPage.faq.admin_space.basics.admin.text_2")
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("h4", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("faqPage.faq.admin_space.basics.access_card")
                        )
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t("faqPage.faq.admin_space.style.title")))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.$t("faqPage.faq.admin_space.style.text")))
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$t("faqPage.faq.admin_space.settings.title"))
                  )
                ]),
                _vm._v(" "),
                _c("ol", [
                  _c("li", [
                    _c("h4", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "faqPage.faq.admin_space.settings.items.title_message_recipient.title"
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "faqPage.faq.admin_space.settings.items.title_message_recipient.text_1"
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "faqPage.faq.admin_space.settings.items.title_message_recipient.text_2"
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "faqPage.faq.admin_space.settings.items.title_message_recipient.text_3"
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "faqPage.faq.admin_space.settings.items.title_message_recipient.text_4"
                          )
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("h4", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "faqPage.faq.admin_space.settings.items.options.title"
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "faqPage.faq.admin_space.settings.items.options.text"
                          )
                        )
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$t("faqPage.faq.admin_space.generate_pdf.title"))
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("faqPage.faq.admin_space.generate_pdf.text_1")
                    )
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("faqPage.faq.admin_space.generate_pdf.text_2")
                    )
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("h2", [
              _vm._v(_vm._s(_vm.$t("faqPage.faq.manage_cards.title")))
            ]),
            _vm._v(" "),
            _c("ol", [
              _c("li", [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$t("faqPage.faq.manage_cards.share_card.title"))
                  )
                ]),
                _vm._v(" "),
                _c("ol", [
                  _c("li", [
                    _c("h4", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "faqPage.faq.manage_cards.share_card.email.title"
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "faqPage.faq.manage_cards.share_card.email.text"
                          )
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("h4", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "faqPage.faq.manage_cards.share_card.url.title"
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "faqPage.faq.manage_cards.share_card.url.text_1"
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "faqPage.faq.manage_cards.share_card.url.text_2"
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "faqPage.faq.manage_cards.share_card.url.text_3"
                          )
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("h4", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "faqPage.faq.manage_cards.share_card.social_network.title"
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "faqPage.faq.manage_cards.share_card.social_network.text_1"
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "faqPage.faq.manage_cards.share_card.social_network.text_2"
                          )
                        )
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("faqPage.faq.manage_cards.close_send_card.title")
                    )
                  )
                ]),
                _vm._v(" "),
                _c("ol", [
                  _c("li", [
                    _c("h4", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "faqPage.faq.manage_cards.close_send_card.close_message_collection"
                          )
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("h4", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "faqPage.faq.manage_cards.close_send_card.send_card.title"
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("ol", [
                      _c("li", [
                        _c("h5", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "faqPage.faq.manage_cards.close_send_card.send_card.email"
                              )
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("h5", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "faqPage.faq.manage_cards.close_send_card.send_card.mail"
                              )
                            )
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("h4", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "faqPage.faq.manage_cards.close_send_card.self_destruction"
                          )
                        )
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$t("faqPage.faq.manage_cards.find_cards.title"))
                  )
                ]),
                _vm._v(" "),
                _c("ol", [
                  _c("li", [
                    _c("h4", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("faqPage.faq.manage_cards.find_cards.email")
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("h4", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "faqPage.faq.manage_cards.find_cards.account.title"
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("ol", [
                      _c("li", [
                        _c("h5", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "faqPage.faq.manage_cards.find_cards.account.ongoing_cards"
                              )
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("h5", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "faqPage.faq.manage_cards.find_cards.account.archived_cards"
                              )
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("h5", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "faqPage.faq.manage_cards.find_cards.account.received_cards"
                              )
                            )
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$t("faqPage.faq.manage_cards.print_card.title"))
                  )
                ]),
                _vm._v(" "),
                _c("ol", [
                  _c("li", [
                    _c("h4", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "faqPage.faq.manage_cards.print_card.home_print.title"
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("ol", [
                      _c("li", [
                        _c("h5", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "faqPage.faq.manage_cards.print_card.home_print.windows.title"
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "text" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "faqPage.faq.manage_cards.print_card.home_print.windows.text_1"
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "text" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "faqPage.faq.manage_cards.print_card.home_print.windows.text_2"
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "text" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "faqPage.faq.manage_cards.print_card.home_print.windows.text_3"
                              )
                            ) + "\n                                            "
                          ),
                          _c("ul", [
                            _c("li", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "faqPage.faq.manage_cards.print_card.home_print.windows.landscape"
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "faqPage.faq.manage_cards.print_card.home_print.windows.double_sided"
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "faqPage.faq.manage_cards.print_card.home_print.windows.binding"
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "faqPage.faq.manage_cards.print_card.home_print.windows.margins"
                                  )
                                )
                              )
                            ])
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("h5", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "faqPage.faq.manage_cards.print_card.home_print.mac.title"
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "text" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "faqPage.faq.manage_cards.print_card.home_print.mac.text_1"
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "text" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "faqPage.faq.manage_cards.print_card.home_print.mac.text_2"
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "text" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "faqPage.faq.manage_cards.print_card.home_print.mac.text_3"
                              )
                            ) + "\n                                            "
                          ),
                          _c("ul", [
                            _c("li", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "faqPage.faq.manage_cards.print_card.home_print.mac.landscape"
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "faqPage.faq.manage_cards.print_card.home_print.mac.double_sided"
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "faqPage.faq.manage_cards.print_card.home_print.mac.binding"
                                  )
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "faqPage.faq.manage_cards.print_card.home_print.mac.margins"
                                  )
                                )
                              )
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("h4", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "faqPage.faq.manage_cards.print_card.professional_print.title"
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "faqPage.faq.manage_cards.print_card.professional_print.text_1"
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(
                            _vm.$t(
                              "faqPage.faq.manage_cards.print_card.professional_print.text_2"
                            )
                          ) +
                          "\n\n                                    "
                      ),
                      _c("ul", [
                        _c("li", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "faqPage.faq.manage_cards.print_card.professional_print.landscape"
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "faqPage.faq.manage_cards.print_card.professional_print.double_sided"
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "faqPage.faq.manage_cards.print_card.professional_print.binding"
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "faqPage.faq.manage_cards.print_card.professional_print.margins"
                              )
                            )
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "faqPage.faq.manage_cards.print_card.professional_print.text_3"
                          )
                        )
                      )
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("sharewell-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-49eefd67", { render: render, staticRenderFns: staticRenderFns })
  }
}