var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "first-page" }, [
      _c("div", { staticClass: "left-page" }),
      _vm._v(" "),
      _c("div", { staticClass: "right-page" }, [
        _c("h3", [
          _vm._v("For " + _vm._s(_vm.recipient)),
          _c("br"),
          _vm._v(_vm._s(_vm.occasion))
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "second-page" },
      _vm._l(Math.ceil(_vm.messages.length / 4), function(i) {
        return _c("div", { staticClass: "row" }, [
          _c(
            "ul",
            _vm._l(_vm.messages.slice((i - 1) * 4, i * 4), function(m) {
              return _c("li", [
                _c("div", { class: _vm.sizeClass(m.text) }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(m.text) +
                      "\n                        "
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", [_vm._v("– " + _vm._s(m.signed_by))])
                ])
              ])
            }),
            0
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-e3660d2a", { render: render, staticRenderFns: staticRenderFns })
  }
}