<template>
    <!-- horizontal-1 -->
    <ins class="adsbygoogle"
        style="display:block"
        data-ad-client="ca-pub-1610284649584943"
        data-ad-slot="1109798194"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
</template>

<script>
export default {
    data: function() {
        return {};
    },
    mounted: function() {
        (adsbygoogle = window.adsbygoogle || []).push({});
    }
};
</script>

<style scoped>
    ins {
        text-align: center;
    }
</style>