var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "app" } }, [
    _c("h1", [_vm._v("Metrics")]),
    _vm._v(" "),
    _c("div", { staticClass: "graphs" }, [
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _c("div", { staticClass: "card" }, [
        _c(
          "ol",
          _vm._l(_vm.generationStylesData.labels, function(theme, index) {
            return _c("li", { key: theme }, [
              _c("picture", [
                _c("source", {
                  attrs: {
                    type: "image/webp",
                    srcset: "/images/previews/" + theme + ".webp"
                  }
                }),
                _vm._v(" "),
                _c("source", {
                  attrs: {
                    type: "image/jp2",
                    srcset: "/images/previews/" + theme + ".jp2"
                  }
                }),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    srcset: "/images/previews/" + theme + ".png",
                    alt: theme + " theme"
                  }
                })
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(theme) +
                    " (" +
                    _vm._s(_vm.generationStylesData.data[index]) +
                    ")"
                )
              ])
            ])
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card" }, [
      _c("canvas", { attrs: { id: "card-chart", width: "400", height: "300" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card" }, [
      _c("canvas", {
        attrs: { id: "message-chart", width: "400", height: "300" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card" }, [
      _c("canvas", {
        attrs: { id: "generation-chart", width: "400", height: "300" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card" }, [
      _c("canvas", {
        attrs: { id: "generation-style-chart", width: "400", height: "300" }
      })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-004b9014", { render: render, staticRenderFns: staticRenderFns })
  }
}