<template>
  <div>
    <h1>{{ $t('cardCreatedConfirmationPage.thankYou') }}</h1>
    <h2>{{ $t('cardCreatedConfirmationPage.confirmationEmailSoon') }}</h2>
    <p>{{ $t('cardCreatedConfirmationPage.writeMessageHere') }} <a v-bind:href="url">{{ $t('cardCreatedConfirmationPage.clickHere') }}</a></p>
  </div>
</template>

<script>
export default {
	data: function() {
		return {
			url: `/message/${this.$route.params.code}`
		};
	}
};
</script>

<style scoped>
    h2 {
        color: green;
    }
</style>
