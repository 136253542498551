var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", [_vm._v(_vm._s(_vm.$t("cardCreatedConfirmationPage.thankYou")))]),
    _vm._v(" "),
    _c("h2", [
      _vm._v(
        _vm._s(_vm.$t("cardCreatedConfirmationPage.confirmationEmailSoon"))
      )
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        _vm._s(_vm.$t("cardCreatedConfirmationPage.writeMessageHere")) + " "
      ),
      _c("a", { attrs: { href: _vm.url } }, [
        _vm._v(_vm._s(_vm.$t("cardCreatedConfirmationPage.clickHere")))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4f67d10c", { render: render, staticRenderFns: staticRenderFns })
  }
}