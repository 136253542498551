<template>
    <select v-model="selectedLang">
        <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
            {{ langNames[lang] }}
        </option>
    </select>
</template>

<script>
    export default {
        name: 'LanguageSelector',
        data() {
            return {
                langs: ['en', 'fr', 'es'],
                langNames: {
                    en: 'English',
                    fr: 'Français',
                    es: 'Español'
                },
                selectedLang: this.$i18n.locale
            }
        },
        watch: {
            selectedLang(newLang) {
                this.$i18n.locale = newLang
                localStorage.setItem('lang', newLang)
            }
        }
    }
</script>

<style scoped>
select {
  height: 38px;
  padding: 0 10px;
  border: 1px solid #d5e4e4;
  border-radius: 10px;
  background-color: white;
  color: #325453;
  font-size: 16px;
  cursor: pointer;
}
</style>