var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("nav", [
        _vm._m(0),
        _vm._v(" "),
        _c("ul", { staticClass: "menu" }, [
          _c("li", [
            _c(
              "a",
              {
                class: { selected: _vm.selectedItem == "style" },
                on: {
                  click: function($event) {
                    _vm.selectedItem = "style"
                    _vm.onClickStyle()
                  }
                }
              },
              [
                _c("img", {
                  attrs: { src: "/images/paint-brush.svg", alt: "Style" }
                }),
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("finishCardPage.style")) +
                    "\n        "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                class: { selected: _vm.selectedItem == "parameters" },
                on: {
                  click: function($event) {
                    _vm.selectedItem = "parameters"
                  }
                }
              },
              [
                _c("img", {
                  attrs: { src: "/images/gears.svg", alt: "Parameters" }
                }),
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("finishCardPage.parameters")) +
                    "\n        "
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "menu-content" }, [
          _c(
            "div",
            {
              staticClass: "styling",
              class: { "show-item": _vm.selectedItem == "style" }
            },
            [
              _c("p", { staticStyle: { "text-align": "center" } }),
              _c(
                "div",
                { staticClass: "theme-picker", attrs: { id: "theme-picker" } },
                _vm._l(_vm.themePreviews, function(themePreview) {
                  return _c(
                    "div",
                    {
                      key: themePreview.name,
                      class: { selected: _vm.theme == themePreview.name },
                      on: {
                        click: function($event) {
                          return _vm.changeTheme(themePreview.name)
                        }
                      }
                    },
                    [
                      _c("picture", [
                        _c("source", {
                          attrs: {
                            type: "image/webp",
                            srcset:
                              "/images/previews/" + themePreview.name + ".webp"
                          }
                        }),
                        _vm._v(" "),
                        _c("source", {
                          attrs: {
                            type: "image/jp2",
                            srcset:
                              "/images/previews/" + themePreview.name + ".jp2"
                          }
                        }),
                        _vm._v(" "),
                        _c("img", {
                          attrs: {
                            srcset:
                              "/images/previews/" + themePreview.name + ".png",
                            alt: themePreview.humanName + " theme"
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(themePreview.humanName))])
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("p"),
              _vm._v(" "),
              !_vm.pdf_loading
                ? _c(
                    "button",
                    {
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.generateCard()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("finishCardPage.generatePDF")))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.pdf_loading
                ? _c("loader", { staticClass: "loader" })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "parameters",
              class: { "show-item": _vm.selectedItem == "parameters" }
            },
            [
              _c(
                "form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.updateCard.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("p", [
                    _c("label", { attrs: { for: "occasion" } }, [
                      _vm._v(_vm._s(_vm.$t("finishCardPage.title")))
                    ]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.title,
                          expression: "title"
                        }
                      ],
                      attrs: {
                        placeholder: _vm.$t("finishCardPage.cardTitle"),
                        id: "title"
                      },
                      domProps: { value: _vm.title },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.title = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("label", { attrs: { for: "recipient" } }, [
                      _vm._v(_vm._s(_vm.$t("finishCardPage.recipient")))
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.receiver_name,
                          expression: "receiver_name"
                        }
                      ],
                      attrs: {
                        placeholder: _vm.$t("finishCardPage.recipient"),
                        id: "recipient",
                        required: ""
                      },
                      domProps: { value: _vm.receiver_name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.receiver_name = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("label", { attrs: { for: "occasion" } }, [
                      _vm._v(_vm._s(_vm.$t("finishCardPage.occasion")))
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.occasion,
                          expression: "occasion"
                        }
                      ],
                      attrs: {
                        placeholder: _vm.$t("finishCardPage.occasion"),
                        id: "occasion",
                        required: ""
                      },
                      domProps: { value: _vm.occasion },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.occasion = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("label", { attrs: { for: "fundraising_link" } }, [
                      _vm._v(_vm._s(_vm.$t("finishCardPage.fundraisingLink")))
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.fundraising_link,
                          expression: "fundraising_link"
                        }
                      ],
                      attrs: {
                        placeholder: _vm.$t(
                          "finishCardPage.fundraisingLinkPlaceholder"
                        ),
                        id: "fundraising_link"
                      },
                      domProps: { value: _vm.fundraising_link },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.fundraising_link = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "horizontal" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.can_upload_images,
                          expression: "can_upload_images"
                        }
                      ],
                      attrs: { type: "checkbox", id: "can_upload_images" },
                      domProps: {
                        checked: Array.isArray(_vm.can_upload_images)
                          ? _vm._i(_vm.can_upload_images, null) > -1
                          : _vm.can_upload_images
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.can_upload_images,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.can_upload_images = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.can_upload_images = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.can_upload_images = $$c
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "can_upload_images" } }, [
                      _vm._v(_vm._s(_vm.$t("finishCardPage.allowImageUpload")))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("label", { attrs: { for: "message" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("finishCardPage.messageToParticipants"))
                      )
                    ]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.message,
                          expression: "message"
                        }
                      ],
                      staticClass: "big-textarea",
                      domProps: { value: _vm.message },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.message = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("label", { attrs: { for: "email_list" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("finishCardPage.inviteParticipantsByEmail")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email_list,
                          expression: "email_list"
                        }
                      ],
                      staticClass: "big-textarea",
                      attrs: {
                        placeholder: _vm.$t("finishCardPage.participantEmails")
                      },
                      domProps: { value: _vm.email_list },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.email_list = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "horizontal" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.notifications_on_new_message,
                          expression: "notifications_on_new_message"
                        }
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "notifications_on_new_message"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.notifications_on_new_message)
                          ? _vm._i(_vm.notifications_on_new_message, null) > -1
                          : _vm.notifications_on_new_message
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.notifications_on_new_message,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.notifications_on_new_message = $$a.concat([
                                  $$v
                                ]))
                            } else {
                              $$i > -1 &&
                                (_vm.notifications_on_new_message = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.notifications_on_new_message = $$c
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      { attrs: { for: "notifications_on_new_message" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("finishCardPage.notificationsForNewMessages")
                          )
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "horizontal" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.activeCoeurCode,
                          expression: "activeCoeurCode"
                        }
                      ],
                      attrs: { type: "checkbox", id: "activeCoeurCode" },
                      domProps: {
                        checked: Array.isArray(_vm.activeCoeurCode)
                          ? _vm._i(_vm.activeCoeurCode, null) > -1
                          : _vm.activeCoeurCode
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.activeCoeurCode,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.activeCoeurCode = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.activeCoeurCode = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.activeCoeurCode = $$c
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", {
                      attrs: { for: "activeCoeurCode" },
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("finishCardPage.includeCoeurCode")
                        )
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    !_vm.saved
                      ? _c(
                          "button",
                          {
                            staticClass: "bubbly-button",
                            class: { animate: _vm.animate },
                            on: {
                              click: function($event) {
                                return _vm.animateButton()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("finishCardPage.save")))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.saved
                      ? _c("span", { staticClass: "saved-text" }, [
                          _vm._v(_vm._s(_vm.$t("finishCardPage.saved")))
                        ])
                      : _vm._e()
                  ])
                ]
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "tooltip",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showTooltip,
              expression: "showTooltip"
            }
          ],
          staticClass: "tooltip"
        },
        [
          _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _c("h3", [_vm._v(_vm._s(_vm.$t("finishCardPage.howToPrintCard")))]),
            _vm._v(" "),
            _c("div", { staticClass: "slider" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.printStep == 1,
                      expression: "printStep == 1"
                    }
                  ],
                  staticClass: "first-step"
                },
                [
                  _c("img", {
                    attrs: { src: "/images/impression-systeme.png" }
                  }),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("finishCardPage.clickFilePrint")))
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.printStep == 2,
                      expression: "printStep == 2"
                    }
                  ],
                  staticClass: "second-step"
                },
                [
                  _c("img", {
                    staticStyle: { width: "350px" },
                    attrs: { src: "/images/parametres-impression.png" }
                  }),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.$t("finishCardPage.setCorrectPrintSettings"))
                    )
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.printStep == 2,
                    expression: "printStep == 2"
                  }
                ],
                staticStyle: { "text-align": "left" }
              },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        _vm.printStep = 1
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("finishCardPage.previous")))]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.printStep == 1,
                    expression: "printStep == 1"
                  }
                ],
                staticStyle: { "text-align": "right" }
              },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        _vm.printStep = 2
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("finishCardPage.next")))]
                )
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "info-icon",
          on: {
            click: function($event) {
              return _vm.toggleShowTooltip()
            }
          }
        },
        [_c("info-icon")],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "global-generate-btn" },
        [
          !_vm.pdf_loading
            ? _c(
                "button",
                {
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.generateCard()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("finishCardPage.generatePDF")))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.pdf_loading ? _c("loader", { staticClass: "loader" }) : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "content",
          class: _vm.card_style,
          attrs: { id: "content" }
        },
        [
          _c("span", { staticClass: "message-url" }, [
            _c("span", [
              _c("img", { attrs: { src: "/images/chains.svg", alt: "link" } }),
              _vm._v(" "),
              _c("span", { staticClass: "share-box-title" }, [
                _vm._v(_vm._s(_vm.$t("finishCardPage.shareLink")))
              ])
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.addMessageURL,
                  expression: "addMessageURL"
                }
              ],
              attrs: { type: "text", id: "messageURL" },
              domProps: { value: _vm.addMessageURL },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.addMessageURL = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "copy",
                attrs: {
                  type: "button",
                  name: "copy",
                  "data-clipboard-target": "#messageURL"
                }
              },
              [_vm._v(_vm._s(_vm.$t("finishCardPage.copyLink")))]
            )
          ]),
          _vm._v(" "),
          _vm.loadingTheme
            ? _c(
                "div",
                { staticClass: "loading-overlay" },
                [_c("loader", { staticClass: "loader" })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "page" }, [
            _c("div", { staticClass: "left-page" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeCoeurCode,
                      expression: "activeCoeurCode"
                    }
                  ],
                  attrs: { id: "qrcode-preview" }
                },
                [
                  _c("img", { attrs: { src: _vm.coeurCodeUrl } }),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("finishCardPage.scanCoeurCodeToParticipate")
                      )
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("h5", [_vm._v("Made with love by Sharewell")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "right-page" }, [
              _c("h3", { staticClass: "card-title" }, [
                _vm._v(_vm._s(_vm.title))
              ]),
              _vm._v(" "),
              _c("h5", [_vm._v("Made with love by Sharewell")])
            ])
          ]),
          _vm._v(" "),
          Math.ceil(_vm.messages.length / 8) % 2 == 0
            ? _c("div", { staticClass: "page" }, [
                _c("div", { staticClass: "left-page" }),
                _vm._v(" "),
                _c("div", { staticClass: "right-page" })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(Math.ceil(_vm.messages.length / 8), function(i) {
            return _c("div", { staticClass: "page" }, [
              _c("div", { staticClass: "left-page" }, [
                _c(
                  "ul",
                  _vm._l(
                    _vm.messages.slice((i - 1) * 8, (i - 1) * 8 + 4),
                    function(m) {
                      return _c("li", { class: { "img-card": m.image } }, [
                        _c("div", [
                          m.image && _vm.can_upload_images
                            ? _c("img", { attrs: { src: m.image } })
                            : _vm._e(),
                          _c("span", {
                            domProps: { innerHTML: _vm._s(m.text) }
                          }),
                          _c("br"),
                          _vm._v(" "),
                          _c("span", { staticClass: "signed-by-container" }, [
                            _c("span", {
                              staticClass: "signed-by",
                              domProps: { innerHTML: _vm._s(m.signed_by) }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "delete-message",
                            on: {
                              click: function($event) {
                                return _vm.deleteMessage(m._id)
                              }
                            }
                          },
                          [_vm._v("\n              ×\n            ")]
                        )
                      ])
                    }
                  ),
                  0
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "right-page" }, [
                _c(
                  "ul",
                  _vm._l(
                    _vm.messages.slice((i - 1) * 8 + 4, (i - 1) * 8 + 8),
                    function(m) {
                      return _c("li", { class: { "img-card": m.image } }, [
                        _c("div", [
                          m.image && _vm.can_upload_images
                            ? _c("img", { attrs: { src: m.image } })
                            : _vm._e(),
                          _c("span", {
                            domProps: { innerHTML: _vm._s(m.text) }
                          }),
                          _c("br"),
                          _vm._v(" "),
                          _c("span", { staticClass: "signed-by-container" }, [
                            _c("span", {
                              staticClass: "signed-by",
                              domProps: { innerHTML: _vm._s(m.signed_by) }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "delete-message",
                            on: {
                              click: function($event) {
                                return _vm.deleteMessage(m._id)
                              }
                            }
                          },
                          [_vm._v("\n              ×\n            ")]
                        )
                      ])
                    }
                  ),
                  0
                )
              ])
            ])
          }),
          _vm._v(" "),
          _vm.deleted_messages.length > 0
            ? _c("h4", { staticClass: "deleted-message-separator" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("finishCardPage.deletedMessagesSeparator")) +
                    "\n    "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(Math.ceil(_vm.deleted_messages.length / 8), function(i) {
            return _c("div", { staticClass: "page deleted-messages" }, [
              _c("div", { staticClass: "left-page" }, [
                _c(
                  "ul",
                  _vm._l(
                    _vm.deleted_messages.slice((i - 1) * 8, (i - 1) * 8 + 4),
                    function(m) {
                      return _c("li", { class: { "img-card": m.image } }, [
                        _c("div", [
                          m.image && _vm.can_upload_images
                            ? _c("img", { attrs: { src: m.image } })
                            : _vm._e(),
                          _c("span", {
                            domProps: { innerHTML: _vm._s(m.text) }
                          }),
                          _c("br"),
                          _vm._v(" "),
                          _c("span", { staticClass: "signed-by-container" }, [
                            _c("span", {
                              staticClass: "signed-by",
                              domProps: { innerHTML: _vm._s(m.signed_by) }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "revert-message",
                            on: {
                              click: function($event) {
                                return _vm.revertMessage(m._id)
                              }
                            }
                          },
                          [_vm._v("\n              ↺\n            ")]
                        )
                      ])
                    }
                  ),
                  0
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "right-page" }, [
                _c(
                  "ul",
                  _vm._l(
                    _vm.deleted_messages.slice(
                      (i - 1) * 8 + 4,
                      (i - 1) * 8 + 8
                    ),
                    function(m) {
                      return _c("li", { class: { "img-card": m.image } }, [
                        _c("div", [
                          m.image && _vm.can_upload_images
                            ? _c("img", { attrs: { src: m.image } })
                            : _vm._e(),
                          _c("span", {
                            domProps: { innerHTML: _vm._s(m.text) }
                          }),
                          _c("br"),
                          _vm._v(" "),
                          _c("span", { staticClass: "signed-by-container" }, [
                            _c("span", {
                              staticClass: "signed-by",
                              domProps: { innerHTML: _vm._s(m.signed_by) }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "revert-message",
                            on: {
                              click: function($event) {
                                return _vm.revertMessage(m._id)
                              }
                            }
                          },
                          [_vm._v("\n              ↺\n            ")]
                        )
                      ])
                    }
                  ),
                  0
                )
              ])
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "content",
          class: _vm.card_style,
          attrs: { id: "full-size-content" }
        },
        [
          _c("div", { staticClass: "page" }, [
            _c("div", { staticClass: "left-page" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeCoeurCode,
                      expression: "activeCoeurCode"
                    }
                  ],
                  attrs: { id: "qrcode-final" }
                },
                [
                  _c("img", { attrs: { src: _vm.coeurCodeUrl } }),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("finishCardPage.scanCoeurCodeToParticipate")
                      )
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _vm._m(1)
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "right-page" }, [
              _c("h3", { staticClass: "card-title" }, [
                _vm._v(_vm._s(_vm.title))
              ]),
              _vm._v(" "),
              _vm._m(2)
            ])
          ]),
          _vm._v(" "),
          Math.ceil(_vm.messages.length / 8) % 2 == 0
            ? _c("div", { staticClass: "page" }, [
                _c("div", { staticClass: "left-page" }),
                _vm._v(" "),
                _c("div", { staticClass: "right-page" })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(Math.ceil(_vm.messages.length / 8), function(i) {
            return _c("div", { staticClass: "page" }, [
              _c("div", { staticClass: "left-page" }, [
                _c(
                  "ul",
                  _vm._l(
                    _vm.messages.slice((i - 1) * 8, (i - 1) * 8 + 4),
                    function(m) {
                      return _c(
                        "li",
                        { class: { deleted: m.deleted, "img-card": m.image } },
                        [
                          _c("div", [
                            m.image && _vm.can_upload_images
                              ? _c("img", { attrs: { src: m.image } })
                              : _vm._e(),
                            _c("span", {
                              domProps: { innerHTML: _vm._s(m.text) }
                            }),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", { staticClass: "signed-by-container" }, [
                              _c("span", {
                                staticClass: "signed-by",
                                domProps: { innerHTML: _vm._s(m.signed_by) }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "delete-message",
                              on: {
                                click: function($event) {
                                  return _vm.deleteMessage(m._id)
                                }
                              }
                            },
                            [_vm._v("\n              ×\n            ")]
                          )
                        ]
                      )
                    }
                  ),
                  0
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "right-page" }, [
                _c(
                  "ul",
                  _vm._l(
                    _vm.messages.slice((i - 1) * 8 + 4, (i - 1) * 8 + 8),
                    function(m) {
                      return _c(
                        "li",
                        { class: { deleted: m.deleted, "img-card": m.image } },
                        [
                          _c("div", [
                            m.image && _vm.can_upload_images
                              ? _c("img", { attrs: { src: m.image } })
                              : _vm._e(),
                            _c("span", {
                              domProps: { innerHTML: _vm._s(m.text) }
                            }),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", { staticClass: "signed-by-container" }, [
                              _c("span", {
                                staticClass: "signed-by",
                                domProps: { innerHTML: _vm._s(m.signed_by) }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "delete-message",
                              on: {
                                click: function($event) {
                                  return _vm.deleteMessage(m._id)
                                }
                              }
                            },
                            [_vm._v("\n              ×\n            ")]
                          )
                        ]
                      )
                    }
                  ),
                  0
                )
              ])
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottom-right-controls" },
        [_c("language-selector")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [
      _c("h1", { staticClass: "sharewell" }, [
        _c("img", { attrs: { src: "/images/logo/logo.svg", alt: "Sharewell" } })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [
      _c("a", { attrs: { href: "http://sharewell.io" } }, [
        _vm._v("Made with love by Sharewell")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [
      _c("a", { attrs: { href: "http://sharewell.io" } }, [
        _vm._v("Made with love by Sharewell")
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2deeb18d", { render: render, staticRenderFns: staticRenderFns })
  }
}